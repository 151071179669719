import axios from "axios";
import {
  SigninInputInterface,
  SigninOutputInterface,
  ConfirmResetPasswordInputInterface,
} from "../interfaces/accounts";

export abstract class AccountService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static signin(input: SigninInputInterface): Promise<SigninOutputInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/account/sign-in`;
      axios
        .post(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar autenticar usuário."));
    });
  }

  static whoAmI(): Promise<SigninOutputInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/account/whoami`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao buscar usuário."));
    });
  }

  static resetPassword(email: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/account/reset-password`;
      axios
        .post(url, { email })
        .then((res) => resolve(res.data))
        .catch(() =>
          reject("Erro ao tentar solicitar alteração de senha de usuário.")
        );
    });
  }

  static confirmResetPassword(
    input: ConfirmResetPasswordInputInterface
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/account/reset-password`;
      axios
        .put(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar alterar de senha de usuário."));
    });
  }
}
