import * as yup from "yup";

export interface RegistrationKeyFormInterface {
  eventId: string;
  prefix: string;
  suffix: string;
  isMasterKey: false;
  quantity: number;
}

export abstract class RegistrationKeyFormInterfaceHandler {
  public static default(): RegistrationKeyFormInterface {
    return {
      eventId: "",
      prefix: "",
      suffix: "",
      isMasterKey: false,
      quantity: 1,
    };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      eventId: yup.string().required("Informe o evento"),
      prefix: yup.string().min(3, "O prefixo deve ter 3 caracteres").required("Informe o prefixo"),
      suffix: yup.string().min(3, "O sufixo deve ter 3 caracteres").required("Informe o sufixo"),
      isMasterKey: yup.boolean().required("Informe se é chave mestra"),
      quantity: yup.number().required("Informe a quantidade"),
    });
  }
}
