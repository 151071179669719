import { create } from "zustand";
import { FormUserInputInterface, UserSearchInputInterface, UserSearchOutputInterface } from "../interfaces";
import { UserService } from "../services";

interface useUserStoreProps {
  search: (input: UserSearchInputInterface) => Promise<UserSearchOutputInterface>;
  create: (input: FormUserInputInterface) => Promise<void>;
  edit: (input: FormUserInputInterface) => Promise<void>;
  delete: (id: string) => Promise<void>;
}

export const useUserStore = create<useUserStoreProps>(() => ({
  search: (input: UserSearchInputInterface) => {
    return UserService.search(input);
  },
  create: (input: FormUserInputInterface) => UserService.create(input),
  edit: (input: FormUserInputInterface) => UserService.edit(input),
  delete: (id: string) => UserService.delete(id),
}));
