import { Divider } from "rsuite";
import styled from "styled-components";

export const ContainerProgress = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;

  @media (max-width: 687px) {
    flex-direction: column;
  }
`;

export const ResponsiveDivider = styled(Divider)`
  width: 100%;
  height: 1px;
  background-color: #ddd;

  @media (max-width: 687px) {
    width: 1px;
    height: 100%;
    margin: 0 10px;
  }
`;