import { List, Placeholder } from "rsuite";
import { ActionListItemInterface } from "./interfaces/action-list-item.interface";
import { ActionListItem } from "./action-list-item/action-list-item.component";
import { ConfirmModal } from "../..";
import { useState } from "react";

interface ActionListProps {
  hover?: boolean;
  pointer?: boolean;
  loading?: boolean;
  items: ActionListItemInterface[];
  onClick?: (target: ActionListItemInterface) => void;
  onEdit?: (target: ActionListItemInterface) => void;
  onRemove?: (target: ActionListItemInterface) => void;
  confirmRemoveMessage?: (target: ActionListItemInterface) => string;
}

export function ActionList(props: ActionListProps) {
  const {
    items,
    onClick,
    onEdit,
    onRemove,
    confirmRemoveMessage: confirmMessage,
    hover,
    pointer,
    loading,
  } = props;
  const [target, setTarget] = useState<ActionListItemInterface | null>(null);
  const [message, setMessage] = useState<string>("");

  function handleClickRemove(item: ActionListItemInterface): void {
    setTarget(item);
    setMessage(
      confirmMessage
        ? confirmMessage(item)
        : "Deseja realmente remover este item?"
    );
  }

  function handleConfirmRemove(): void {
    onRemove && onRemove(target!);
    setTarget(null);
  }

  if (loading) {
    return <Placeholder.Paragraph style={{ marginTop: 10 }} rows={10} active />;
  }

  return (
    <>
      <List hover={hover}>
        {items.map((item, index) => (
          <ActionListItem
            pointer={pointer}
            key={index}
            item={item}
            onEdit={onEdit}
            onRemove={onRemove ? handleClickRemove : undefined}
            onClick={onClick}
          />
        ))}
      </List>
      <ConfirmModal
        open={Boolean(target)}
        onConfirm={handleConfirmRemove}
        onClose={() => setTarget(null)}
        title="Remover"
      >
        {message}
      </ConfirmModal>
    </>
  );
}
