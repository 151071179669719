import { DiscountTypeEnum } from "../../enums";

export interface VoucherInterface {
  id: string;
  name: string;
  discountType: DiscountTypeEnum;
  maxRedemptions: number;
  numberOfRedemptions: number;
  isActive: boolean;
  activeAt: Date;
  expiresAt?: Date;
  eventId?: string;
  discountAmount: number;
}

export abstract class VoucherInterfaceHandler {
  public static fromApi(data: any): VoucherInterface {
    const result: VoucherInterface = {
      id: data.id,
      name: data.name,
      discountType: data.discountType,
      maxRedemptions: data.maxRedemptions,
      numberOfRedemptions: data.numberOfRedemptions,
      isActive: data.isActive,
      activeAt: new Date(Date.parse(data.activeAt)),
      eventId: data.eventId,
      discountAmount: data.discountAmount,
    };

    if (data.expiresAt) {
      result.expiresAt = new Date(Date.parse(data.expiresAt));
    }

    return result;
  }

  public static toApi(data: VoucherInterface): any {
    const result: any = {
      id: data.id,
      name: data.name,
      discountType: data.discountType,
      maxRedemptions: data.maxRedemptions,
      numberOfRedemptions: data.numberOfRedemptions,
      isActive: data.isActive,
      activeAt: data.activeAt.toISOString(),
      eventId: data.eventId,
      discountAmount: data.discountAmount,
    };

    if (data.expiresAt) {
      result.expiresAt = data.expiresAt?.toISOString();
    }

    return result;
  }
}
