import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useStore } from "zustand";
import { useAccountStore, useToasterStore } from "../stores";

function getFromLocalStorage(key: string): any {
  const data = window.localStorage.getItem(key) || "";
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
}

axios.interceptors.request.use(
  (config: any) => {
    const auth = (getFromLocalStorage("account-store") || {}).state || {};
    if (auth && auth.token && auth.token.length !== 0) {
      config.headers["Authorization"] = `Bearer ${auth.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (!error || !error.response) {
      return Promise.reject({
        response: {
          data: { message: "Erro desconhecido" },
        },
      });
    }

    const navigate = useNavigate();
    const accountStore = useStore(useAccountStore);
    const toasterStore = useStore(useToasterStore);

    const status = error.response.status;

    if (status === 401) {
      accountStore.signout();
      navigate("/");
    }

    if (status === 403) {
      toasterStore.warning("Você não tem permissão para acessar essa rota.");
      navigate("/");
    }

    return Promise.reject(error);
  }
);
