/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FlexboxGrid } from "rsuite";
import {
  Col24,
  FormModal,
  GeneralUtils,
  InputSelect,
  InputSelectAssociation,
  InputText,
  RoleEnum,
  RoleEnumHandler,
  useAccountStore,
  UserRegistryInterface,
  UserRegistryInterfaceHandler,
} from "../../../shared";
import { useStore } from "zustand";

interface UserFormProps {
  user?: UserRegistryInterface | null;
  onClose?: () => void;
  onSave?: (user: UserRegistryInterface) => void;
}

export function UserForm(props: UserFormProps) {
  const [user, setUser] = useState<UserRegistryInterface>(UserRegistryInterfaceHandler.default());
  const [errors, setErrors] = useState<any>({});
  const userLogged = useStore(useAccountStore);
  const schema = UserRegistryInterfaceHandler.schema(!!props.user?.id);

  useEffect(() => {
    setErrors({});
    props.user && setUser(props.user);
  }, [props.user]);

  function handleChangeValue(key: string, value: any): void {
    setUser({ ...user!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(user, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(user!);
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)));
  }

  return (
    <FormModal
      open={Boolean(props.user)}
      title={user.id ? `Editar usuário ${user.name}` : `Criar usuário`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!user.id ? (
        <FlexboxGrid justify="space-between" align="middle" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <InputText
              label="Nome"
              value={user.name}
              error={errors.name}
              onChange={(val) => handleChangeValue("name", val)}
            />
          </Col24>
          <Col24>
            <InputText
              label="Email"
              value={user.email}
              error={errors.email}
              onChange={(val) => handleChangeValue("email", val)}
            />
          </Col24>
          {userLogged.role === RoleEnum.Admin && (
            <>
              <Col24>
                <InputSelect
                  label="Cargo"
                  error={errors.role}
                  options={[
                    {
                      label: RoleEnumHandler.label(RoleEnum.Admin),
                      value: RoleEnum.Admin,
                    },
                    {
                      label: RoleEnumHandler.label(RoleEnum.Staff),
                      value: RoleEnum.Staff,
                    },
                  ]}
                  value={user.role}
                  onChange={(val) => handleChangeValue("role", val)}
                />
              </Col24>
              <Col24>
                <InputSelectAssociation
                  label="Associação"
                  value={user.associationId}
                  error={errors.associationId}
                  onChange={(val) => handleChangeValue("associationId", val)}
                />
              </Col24>
            </>
          )}
        </FlexboxGrid>
      ) : (
        <FlexboxGrid justify="space-between" align="middle" style={{ padding: 16 }}>
          <Col24>
            <InputSelect
              label="Cargo"
              error={errors.role}
              options={[
                {
                  label: RoleEnumHandler.label(RoleEnum.Admin),
                  value: RoleEnum.Admin,
                },
                {
                  label: RoleEnumHandler.label(RoleEnum.Staff),
                  value: RoleEnum.Staff,
                },
              ]}
              value={user.role}
              onChange={(val) => handleChangeValue("role", val)}
            />
          </Col24>
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
