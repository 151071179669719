import { ReactNode } from "react";
import { Col, FlexboxGrid } from "rsuite";
import { useNavigate } from "react-router";

import { Appbar } from "../appbar";
import { MenuWeb } from "./web-template-sidebar/web-template-sidebar.component";
import { ContentContainer } from "./web-template.styles";

interface WebProps {
  children: ReactNode | null | undefined;
  onSignout?: () => void;
}
export function WebTemplate(props: WebProps) {
  const navigate = useNavigate();

  function handleSignout() {
    props.onSignout && props.onSignout();
  }

  return (
    <>
      <Appbar onHome={() => navigate("/")} />
      <FlexboxGrid justify="space-between" align="top">
        <Col md={6} lg={6} xl={4} xxl={4} style={{ padding: 0, margin: 0 }}>
          <MenuWeb onSignout={handleSignout} />
        </Col>
        <ContentContainer
          className="ns-content"
          md={18}
          lg={18}
          xl={20}
          xxl={20}
        >
          {props.children}
        </ContentContainer>
      </FlexboxGrid>
    </>
  );
}
