import axios from "axios";
import {
  AssociationInterface,
  AssociationSearchInputInterface,
  AssociationSearchOutputInterface,
  AssociationUpsertInputInterface,
} from "../interfaces";

export abstract class AssociationService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static create(
    input: AssociationUpsertInputInterface
  ): Promise<AssociationInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/associations`;
      axios
        .post(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar criar nova associação."));
    });
  }

  static update(
    id: string,
    input: AssociationUpsertInputInterface
  ): Promise<AssociationInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/associations/${id}`;
      axios
        .put(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar atualizar associação."));
    });
  }

  static search(
    input: AssociationSearchInputInterface
  ): Promise<AssociationSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();

    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/associations?${params}`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar obter lista de associações."));
    });
  }
}
