import { create } from "zustand";
import { persist } from "zustand/middleware";
import { GenderEnum, RoleEnum } from "../enums";
import {
  ConfirmResetPasswordInputInterface,
  SigninInputInterface,
  SigninOutputInterface,
} from "../interfaces/accounts";
import { AccountService } from "../services";

interface useAccountStoreProps {
  id: string;
  name: string;
  gender: GenderEnum;
  email: string;
  role: RoleEnum;
  token: string;
  authenticated: boolean;
  signin: (input: SigninInputInterface) => Promise<SigninOutputInterface>;
  whoAmI: () => Promise<SigninOutputInterface>;
  resetPassword: (email: string) => Promise<boolean>;
  confirmResetPassword: (
    input: ConfirmResetPasswordInputInterface
  ) => Promise<boolean>;
  signout: () => void;
}

export const useAccountStore = create(
  persist<useAccountStoreProps>(
    (set, get) => ({
      id: "",
      name: "N/I",
      email: "",
      gender: GenderEnum.Male,
      role: RoleEnum.Standard,
      token: "",
      authenticated: false,

      signin: (input: SigninInputInterface): Promise<SigninOutputInterface> => {
        return new Promise((resolve, reject) => {
          AccountService.signin(input)
            .then((output: SigninOutputInterface): void => {
              set({
                ...get(),
                id: output.user.id,
                name: output.user.name || "N/I",
                email: output.user.email,
                gender: output.user.genderName as GenderEnum,
                role: output.user.userRole as RoleEnum,
                token: output.jwt,
                authenticated: true,
              });
              resolve(output);
            })
            .catch(reject);
        });
      },

      whoAmI: (): Promise<SigninOutputInterface> => AccountService.whoAmI(),

      resetPassword: (email: string) => AccountService.resetPassword(email),

      confirmResetPassword: (input: ConfirmResetPasswordInputInterface) => {
        return AccountService.confirmResetPassword(input);
      },

      signout: () => {
        set({
          ...get(),
          id: "",
          name: "N/I",
          gender: GenderEnum.Male,
          role: RoleEnum.Standard,
          token: "",
          authenticated: false,
        });
      },
    }),

    { name: "account-store" }
  )
);
