/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from "react";
import { useStore } from "zustand";
import { useEventStore, useToasterStore } from "../../stores";
import { EventSearchInputInterface } from "../../interfaces";
import { Placeholder } from "rsuite";
import { InputSelect } from "./input-select.component";

interface Option {
  label: string | ReactNode;
  value: any;
}
interface InputSelectEventProps {
  label?: string | null | undefined;
  value?: any | null | undefined;
  error?: string | null | undefined;
  onChange?: (value: any) => void;
}

export function InputSelectEvent(props: InputSelectEventProps) {
  const toasterStore = useStore(useToasterStore);
  const eventStore = useStore(useEventStore);
  const [options, setOptions] = useState<Option[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    loadOptions();
  }, []);

  function loadOptions() {
    const input: EventSearchInputInterface = {
      page: 0,
      pageSize: 1000,
      filter: "",
    };

    setProcessing(true);

    eventStore
      .search(input)
      .then((output) =>
        setOptions(output.items.map((i) => ({ label: i.name, value: i.id })))
      )
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  if (processing) {
    return <Placeholder.Paragraph rows={3} active />;
  }

  return (
    <InputSelect
      label={props.label || "Eventos"}
      searchable={true}
      options={options}
      value={props.value}
      error={props.error}
      onChange={(val) => props.onChange && props.onChange(val)}
    />
  );
}
