import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 90px;
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
`;

export const ImageCropPreview = styled.img`
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`;
