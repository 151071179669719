import { Col, Grid } from "rsuite";
import { WebTemplate } from "./web-template";
import { ReactNode, useState } from "react";
import { MobileTemplate } from "./mobile-template";
import { useStore } from "zustand";

import useBreakpoint from "use-breakpoint";
import { useAccountStore } from "../../../stores";
import { ConfirmModal } from "../../modals";

interface MasterpageProps {
  children: ReactNode | null | undefined;
}

export function AuthorizedMasterpage(props: MasterpageProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const accountStore = useStore(useAccountStore);
  const { breakpoint } = useBreakpoint({ xs: 0, sm: 576, md: 768, lg: 992, xl: 1200, xxl: 1400 }, "xs");

  function handleSignout(): void {
    accountStore.signout();
    setOpenModal(false);
  }

  return (
    <>
      <Grid style={{ width: "100%", margin: 0, padding: 0 }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{ border: 0, padding: 0 }}>
          {["lg", "xl", "xxl"].includes(breakpoint) && (
            <WebTemplate onSignout={() => setOpenModal(true)}>{props.children}</WebTemplate>
          )}
          {["xs", "sm", "md"].includes(breakpoint) && (
            <MobileTemplate onSignout={() => setOpenModal(true)}>{props.children}</MobileTemplate>
          )}
        </Col>
      </Grid>
      <ConfirmModal
        open={openModal}
        title="Sair"
        confirmText="Sair"
        onConfirm={handleSignout}
        onClose={() => setOpenModal(false)}
      >
        <h5>Deseja realmente sair?</h5>
      </ConfirmModal>
    </>
  );
}
