import { Button } from "rsuite";
import styled from "styled-components";

export const MenuButton = styled(Button)`
  display: flex;
  justify-content: start;
  height: 64px;
  border-radius: 0px;
  text-align: left;
  margin: 0px;
`;
