import * as yup from "yup";

export interface EventLotFormInterface {
  id?: string;
  eventId?: string;
  associationId?: string;
  stateId?: string;
  cityId?: string;
  price: number;
  quantityAvailable: number;
  address: string;
  description: string;
}

export abstract class EventLotFormInterfaceHandler {
  public static default(): EventLotFormInterface {
    return {
      price: 0,
      quantityAvailable: 0,
      address: "",
      description: "",
    };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      associationId: yup
        .string()
        .min(1, "Informe a associação")
        .required("Informe a associação"),
      stateId: yup
        .string()
        .min(1, "Informe o estado")
        .required("Informe o estado"),
      cityId: yup
        .string()
        .min(1, "Informe a cidade")
        .required("Informe a cidade"),
      price: yup
        .number()
        .min(0, "O preço deve ser positivo")
        .required("Informe o preço"),
      quantityAvailable: yup
        .number()
        .min(0, "A quantidade deve ser positiva")
        .required("Informe a quantidade"),
      address: yup
        .string()
        .min(1, "Informe o endereço")
        .required("Informe o endereço"),
    });
  }
}
