export enum DiscountTypeEnum {
  Unit = "unit",
  Percentage = "percentage",
}

export abstract class DiscountTypeEnumHandler {
  public static label(val: DiscountTypeEnum): string {
    switch (val) {
      case DiscountTypeEnum.Unit:
        return "Unidade";
      default:
        return "Porcentagem";
    }
  }

  public static selectOptions(): any[] {
    return Object.values(DiscountTypeEnum).map((val: any) => ({
      label: DiscountTypeEnumHandler.label(val),
      value: val,
    }));
  }
}
