import { useEffect, useState } from "react";
import {
  Col12,
  Col24,
  Col6,
  FormModal,
  GeneralUtils,
  InputNumber,
  InputSelectEvent,
  InputText,
  RegistrationKeyFormInterface,
  RegistrationKeyFormInterfaceHandler,
  RoleEnum,
  useAccountStore,
} from "../../shared";
import { FlexboxGrid, Placeholder, Toggle } from "rsuite";
import { useStore } from "zustand";

interface RegistrationKeyFormProps {
  onClose?: () => void;
  onSave?: (registrationKey: RegistrationKeyFormInterface) => void;
}

export function RegistrationKeyForm(props: RegistrationKeyFormProps) {
  const [registrationKey, setRegistrationKey] = useState<RegistrationKeyFormInterface>(
    RegistrationKeyFormInterfaceHandler.default()
  );
  const [errors, setErrors] = useState<any>({});
  const schema = RegistrationKeyFormInterfaceHandler.schema();

  const user = useStore(useAccountStore);

  useEffect(() => {
    setErrors({});
  }, []);

  function handleChangeValue(key: string, value: any): void {
    setRegistrationKey({ ...registrationKey!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(registrationKey, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(registrationKey!);
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)));
  }
  return (
    <FormModal
      open={Boolean(registrationKey)}
      title={`Criar chave de incrição`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!registrationKey ? (
        <Placeholder.Paragraph rows={2} active />
      ) : (
        <FlexboxGrid justify="space-between" align="middle" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <InputSelectEvent
              value={registrationKey.eventId}
              error={errors.eventId}
              onChange={(value) => handleChangeValue("eventId", value)}
            />
          </Col24>
          <Col6>
            <InputText
              label="Prefixo"
              value={registrationKey.prefix}
              onChange={(value) => handleChangeValue("prefix", value)}
              error={errors.prefix}
              options={{ maxLength: 3 }}
            />
          </Col6>
          <Col6>
            <InputText
              label="Sufixo"
              value={registrationKey.suffix}
              onChange={(value) => handleChangeValue("suffix", value)}
              error={errors.suffix}
              options={{ maxLength: 3 }}
            />
          </Col6>
          <Col6>
            <InputNumber
              label="Quantidade"
              options={{ min: 1 }}
              value={registrationKey.quantity}
              onChange={(value) => handleChangeValue("quantity", value)}
              error={errors.quantity}
            />
          </Col6>
          {user?.role === RoleEnum.Admin && (
            <Col12>
              <Toggle
                defaultValue={0}
                size="lg"
                checkedChildren="ativo"
                unCheckedChildren="inativo"
                checked={registrationKey.isMasterKey}
                onChange={(val) => handleChangeValue("isMasterKey", val)}
                disabled={user?.role !== RoleEnum.Admin}
                alt="Chave mestra"
              >
                Chave mestra
              </Toggle>
            </Col12>
          )}
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
