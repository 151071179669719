/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "zustand";
import {
  ActionList,
  AssociationInterface,
  AssociationSearchOutputInterface,
  InputSearch,
  AssociationUpsertInputInterface,
  Pagination,
  PaginationInterface,
  PaginationInterfaceHandler,
  PanelBase,
  useAssociationStore,
  useToasterStore,
  AssociationFormInterfaceHandler,
} from "../../shared";
import { useEffect, useState } from "react";
import { AssociationForm } from "./association-form.component";

export function Associations() {
  const toasterStore = useStore(useToasterStore);
  const associationStore = useStore(useAssociationStore);

  const [associations, setAssociations] = useState<AssociationInterface[]>([]);
  const [association, setAssociation] = useState<AssociationInterface | null>(
    null
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );

  useEffect(() => {
    loadAssociations(pagination);
  }, []);

  function loadAssociations(input: PaginationInterface): void {
    setProcessing(true);

    associationStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: AssociationSearchOutputInterface) => {
        setPagination({ ...input, count: output.count });
        setAssociations(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination!,
      filter: search,
    };
    setPagination(newPagination);
    loadAssociations(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadAssociations(value);
  }

  function handleSave(association: AssociationInterface): void {
    const input: AssociationUpsertInputInterface = {
      name: association.name,
      code: association.code,
    };

    setProcessing(true);

    if (association.id) {
      associationStore
        .update(association.id, input)
        .then(() => {
          toasterStore.success("Associação atualizado com sucesso!");
          setAssociation(null);
          loadAssociations(pagination);
        })
        .catch(toasterStore.error)
        .finally(() => setProcessing(false));
    } else {
      associationStore
        .create(input)
        .then(() => {
          toasterStore.success("Associação criado com sucesso!");
          setAssociation(null);
          loadAssociations(pagination);
        })
        .catch(toasterStore.error)
        .finally(() => setProcessing(false));
    }
  }

  return (
    <PanelBase
      title={`Associações (${pagination.count})`}
      onAdd={() => setAssociation(AssociationFormInterfaceHandler.default())}
      addButtonTitle="Criar"
    >
      <InputSearch
        placeholder="Filtrar"
        debounce={500}
        onChange={(i) => handleSearch(i)}
      />
      <ActionList
        hover={true}
        loading={processing}
        onEdit={(item) => setAssociation(item.value)}
        items={associations.map((association) => ({
          primary: association.name,
          secondary: `Código: ${association.code}`,
          value: association,
        }))}
      />
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
      <AssociationForm
        association={association}
        onClose={() => setAssociation(null)}
        onSave={(association) => handleSave(association)}
      />
    </PanelBase>
  );
}
