import { GenderEnum, PaymentMethodEnum, PaymentStatusEnum } from "../../enums";

export interface RegistrationInterface {
  id: string;
  totalPrice: number;
  totalDiscount: number;
  purchaseDate: string;
  name: string;
  email: string;
  phone: string;
  birthdate: string;
  gender: GenderEnum;
  userId: string;
  eventId: string;
  eventName: string;
  eventBannerUrl: string;
  eventStartDate: string;
  eventStartTime: string;
  eventEndDate: string;
  eventEndTime: string;
  lotId: string;
  associationId: string;
  associationName: string;
  lotDescription: string;
  cityId: string;
  cityName: string;
  registrationKeyId: string;
  registrationKeyValue: string;
  paymentMethod: PaymentMethodEnum;
  paymentStatus: PaymentStatusEnum;
  cancelledAt?: Date;
}

export abstract class RegistrationInterfaceHandler {
  public static fromApi(data: any): RegistrationInterface {
    const result: RegistrationInterface = {
      id: data.id,
      totalPrice: data.totalPrice,
      totalDiscount: data.totalDiscount,
      purchaseDate: data.purchaseDate,
      name: data.name,
      email: data.email,
      phone: data.phone,
      birthdate: data.birthdate,
      gender: data.gender,
      userId: data.userId,
      eventId: data.eventId,
      eventName: data.eventName,
      eventBannerUrl: data.eventBannerUrl,
      eventStartDate: data.eventStartDate,
      eventStartTime: data.eventStartTime,
      eventEndDate: data.eventEndDate,
      eventEndTime: data.eventEndTime,
      lotId: data.lotId,
      associationId: data.associationId,
      associationName: data.associationName,
      lotDescription: data.lotDescription,
      cityId: data.cityId,
      cityName: data.cityName,
      registrationKeyId: data.registrationKeyId,
      registrationKeyValue: data.registrationKeyValue,
      paymentMethod: data.paymentMethod,
      paymentStatus: data.paymentStatus,
    };

    if (data.cancelledAt) {
      result.cancelledAt = new Date(Date.parse(data.cancelledAt));
    }

    return result;
  }
}
