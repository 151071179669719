import { create } from "zustand";
import { DashboardInterface } from "../interfaces";
import { DashboardService } from "../services";

interface useDashboardStoreProps {
  search: () => Promise<DashboardInterface>;
}

export const useDashboardStore = create<useDashboardStoreProps>(() => ({
  search: () => DashboardService.search(),
}));
