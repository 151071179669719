import { Button, FlexboxGrid, Loader } from "rsuite";
import { GeneralUtils, InputText, PanelBase, useAccountStore, useToasterStore } from "../../../shared";
import { useState } from "react";
import {
  ConfirmResetPasswordInputInterface,
  ConfirmResetPasswordInputInterfaceHandler,
  SigninInputInterfaceHandler,
} from "../../../shared/interfaces/accounts";
import { useStore } from "zustand";
import { useNavigate } from "react-router-dom";

export function ConfirmResetPassword() {
  const navigate = useNavigate();
  const toasterStore = useStore(useToasterStore);
  const accountStore = useStore(useAccountStore);
  const [processing, setProcessing] = useState<boolean>(false);
  const [payload, setPayload] = useState<ConfirmResetPasswordInputInterface>(
    ConfirmResetPasswordInputInterfaceHandler.default()
  );
  const schema: any = SigninInputInterfaceHandler.schema();
  const [errors, setErrors] = useState<any>({});

  function handleChange(key: string, value: any): void {
    setPayload({ ...payload, [key]: value });
  }

  function handleSubmit(): void {
    schema
      .validate(payload, { abortEarly: false })
      .then(() => {
        setErrors({});
        setProcessing(true);
        accountStore
          .confirmResetPassword(payload)
          .then(() => {
            toasterStore.success("Senha alterada com sucesso");
            navigate("/");
          })
          .catch(toasterStore.error);
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)))
      .finally(() => setProcessing(false));
  }

  if (processing) {
    return <Loader size="md" center content="Aguarde..." />;
  }

  return (
    <PanelBase title="Alteração de senha" hideTitleDivider>
      <FlexboxGrid style={{ gap: 32, justifyContent: "center" }}>
        <img src="/logo.png" alt="Logo" style={{ width: 150 }} />
        <InputText
          label="Email"
          value={payload.email}
          error={errors.email}
          onChange={(val) => handleChange("email", val)}
        />
        <InputText
          label="Código"
          value={payload.token}
          error={errors.token}
          onChange={(val) => handleChange("token", val)}
        />
        <InputText
          label="Senha"
          value={payload.password}
          options={{ type: "password" }}
          error={errors.password}
          onChange={(val) => handleChange("password", val)}
        />
        <InputText
          label="Confirme a senha"
          value={payload.passwordConfirmation}
          options={{ type: "password" }}
          error={errors.passwordConfirmation}
          onChange={(val) => handleChange("passwordConfirmation", val)}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ marginTop: 32 }}>
        <Button block appearance="primary" onClick={handleSubmit}>
          Alterar senha
        </Button>
        <Button block appearance="link" onClick={() => navigate("/")}>
          Entrar
        </Button>
      </FlexboxGrid>
    </PanelBase>
  );
}
