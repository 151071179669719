import { EventLotInterface, GeneralUtils, Property } from "../../shared";
import { FlexboxGrid } from "rsuite";

interface LotDetailsProps {
  lot: EventLotInterface;
}

export function LotDetails(props: LotDetailsProps) {
  const { lot } = props;

  return (
    <FlexboxGrid justify="space-between" align="top">
      <Property label="Descrição" title={lot.description} colSize={24} />
      <Property label="Endereço" title={lot.address} colSize={24} />
      <Property label="Cidade" title={lot.cityName} colSize={12} />
      <Property label="Estado" title={lot.stateName} colSize={12} />
      <Property label="Associação" title={lot.associationName} colSize={24} />
      <Property
        label="Quantidade disponível"
        title={lot.quantityAvailable}
        colSize={12}
      />
      <Property
        label="Preço"
        title={GeneralUtils.getAmountLabel(lot.price)}
        colSize={12}
      />
    </FlexboxGrid>
  );
}
