import { PaginationInputInterface } from "./pagination-input.interface";

export interface PaginationInterface {
  page: number;
  pageSize: number;
  count: number;
  filter: string;
  eventId?: string;
  userName?: string;
  userEmail?: string;
  registrationKeyPrefix?: string;
  registrationKeySuffix?: string;
}

export abstract class PaginationInterfaceHandler {
  public static default(): PaginationInterface {
    return {
      page: 0,
      pageSize: 10,
      count: 0,
      filter: "",
      eventId: "",
      userName: "",
      userEmail: "",
      registrationKeyPrefix: "",
      registrationKeySuffix: "",
    };
  }

  public static toPaginationInput(
    pagination: PaginationInterface
  ): PaginationInputInterface {
    const result: any = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      filter: pagination.filter,
    };

    if (pagination.eventId !== "") {
      result.eventId = pagination.eventId;
    }
    if (pagination.userName !== "") {
      result.userName = pagination.userName;
    }
    if (pagination.userEmail !== "") {
      result.userEmail = pagination.userEmail;
    }
    if (pagination.registrationKeyPrefix !== "") {
      result.registrationKeyPrefix = pagination.registrationKeyPrefix;
    }
    if (pagination.registrationKeySuffix !== "") {
      result.registrationKeySuffix = pagination.registrationKeySuffix;
    }

    return result;
  }
}
