import {
  ActionSection,
  DateUtils,
  EventInterface,
  EventLotFormInterface,
  Property,
  Spacer,
} from "../../shared";
import { Avatar, Col, FlexboxGrid, Panel, Row, Stack } from "rsuite";
import { Lots } from "./lots.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPencil } from "@fortawesome/free-solid-svg-icons";

interface EventDetailsProps {
  event: EventInterface;
  onEdit: (event: EventInterface) => void;
  onCreateLot: (lot: EventLotFormInterface) => void;
  onEditLot: (lot: EventLotFormInterface) => void;
}

export function EventDetails(props: EventDetailsProps) {
  const { event, onEdit, onCreateLot, onEditLot } = props;

  return (
    <Panel
      header={
        <ActionSection
          actions={[{ icon: faPencil, onClick: () => onEdit(event) }]}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
          &nbsp;{event.name}
        </ActionSection>
      }
      bordered
    >
      <Panel header="Detalhes" expanded bordered>
        <FlexboxGrid justify="space-between" align="top">
          <Stack>
            <Avatar
              size="xxl"
              src={event.bannerImageUrl}
              style={{ marginRight: 10 }}
            />
            <Stack.Item grow={1}>
              <Row>
                <Property
                  label="Nome do evento"
                  title={event.name}
                  colSize={24}
                />
                <Col style={{ padding: 0 }}>
                  <Property
                    label="Início"
                    title={DateUtils.localTime(
                      event.startDate.toISOString(),
                      true
                    )}
                    colSize={12}
                  />
                  <Property
                    label="Término"
                    title={DateUtils.localTime(
                      event.endDate.toISOString(),
                      true
                    )}
                    colSize={12}
                  />
                </Col>
              </Row>
            </Stack.Item>
          </Stack>
          <Property label="Descrição" title={event.description} colSize={24} />
          <Property label="Cidade" title={event.cityName} colSize={12} />
          <Property label="Estado" title={event.stateName} colSize={12} />
          <Property
            label="Associação"
            title={event.associationName}
            colSize={24}
          />
          <Property
            label="Status"
            title={!event.isDeleted ? "Ativo" : "Inativo"}
            colSize={24}
          />
        </FlexboxGrid>
      </Panel>
      <Spacer />
      <div style={{ height: 10 }} />
      <Lots
        eventId={event.id}
        lots={event.lots || []}
        onCreate={onCreateLot}
        onEdit={onEditLot}
      />
    </Panel>
  );
}
