import { create } from "zustand";
import {
  VoucherInterface,
  VoucherSearchInputInterface,
  VoucherSearchOutputInterface,
  VoucherUpsertInputInterface,
} from "../interfaces";
import { VoucherService } from "../services";

interface useVoucherStoreProps {
  create: (input: VoucherUpsertInputInterface) => Promise<VoucherInterface>;
  update: (
    id: string,
    input: VoucherUpsertInputInterface
  ) => Promise<VoucherInterface>;
  search: (
    input: VoucherSearchInputInterface
  ) => Promise<VoucherSearchOutputInterface>;
}

export const useVoucherStore = create<useVoucherStoreProps>(() => ({
  create: (input: VoucherUpsertInputInterface) => VoucherService.create(input),
  update: (id: string, input: VoucherUpsertInputInterface) =>
    VoucherService.update(id, input),
  search: (input: VoucherSearchInputInterface) => {
    return VoucherService.search(input);
  },
}));
