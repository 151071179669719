import axios from "axios";
import {
  StateSearchInputInterface,
  StateSearchOutputInterface,
} from "../interfaces";

export abstract class StateService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(
    input: StateSearchInputInterface
  ): Promise<StateSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/states/search?${params}`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar obter lista de estados."));
    });
  }
}
