export class GeneralUtils {
  static getErrorMessage(error: any, defaultMsg: string): string {
    if (
      error &&
      error.response &&
      error.response &&
      error.response.data &&
      error.response.data.message
    ) {
      return error.response.data.message;
    }

    return defaultMsg;
  }

  static getQuantityLabel(val: number): string {
    return `${val > 1 && val < 10 ? "0" : ""}${val}`;
  }

  static getAmountLabel(cents: number): string {
    const total = cents / 100;
    return total.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  static getPercentDifference(a: number, b: number): string {
    return `${Math.round(Math.abs((a - b) / ((a + b) / 100)))}%`;
  }

  static clamp(val: number, min: number, max: number = Infinity): number {
    return Math.min(Math.max(val, min), max);
  }

  static maskPhonenumber(phoneNumber: string): string {
    if (!phoneNumber || phoneNumber.length < 11) {
      return phoneNumber || "";
    }

    return phoneNumber
      .toString()
      .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
  }

  static maskCpfCnpj(cpfCnpj: string): string {
    let result = cpfCnpj.replace(/\D/g, "");
    if (result.length === 11) {
      return cpfCnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }

    return cpfCnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      "$1.$2.$3/$4-$5"
    );
  }

  static getSulfixLabel(val: any, separator: string = "/"): string {
    if (
      !val ||
      (typeof val === "string" && val.length === 0) ||
      val === Infinity
    ) {
      return "";
    }

    return `${separator}${val}`;
  }

  static takeValidationErrorObject(error: any): any {
    return error.inner.reduce((acc: any, curr: any) => {
      acc[curr.path] = curr.message;
      return acc;
    }, {});
  }

  static getEnumValues(rawValues: any[]): any[] {
    return rawValues.slice(rawValues.length / 2);
  }
}
