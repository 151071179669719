import { FormModal } from "../../shared";

interface RegistrationKeyModalSuccessComponentProps {
  open: boolean;
  onClose: () => void;
  onDownload: () => void;
}

export function RegistrationKeyModalSuccessComponent(props: RegistrationKeyModalSuccessComponentProps) {
  return (
    <FormModal
      open={props.open}
      title={`Chave de inscrição cadastrada com sucesso!`}
      onClose={props.onClose}
      onSave={props.onDownload}
      saveText={"Baixar chaves de inscrição"}
      closeText={"Fechar"}
    >
      <p>Deseja baixar as chaves de inscrição ?</p>
    </FormModal>
  );
}
