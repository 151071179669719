import {
  faUser,
  faRightFromBracket,
  faBuilding,
  faCalendar,
  faKey,
  faTicket,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { MenuButton } from "./menu-options.styles";
import { useStore } from "zustand";
import { useAccountStore } from "../../../../stores";
import { RoleEnum } from "../../../../enums";

interface MenuOptionsProps {
  onGo: (path: string) => void;
  onSignout: () => void;
}

export function MenuOptions(props: MenuOptionsProps) {
  const user = useStore(useAccountStore);

  return (
    <>
      <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/admin/users")}>
        <FontAwesomeIcon icon={faUser} />
        &nbsp; Usuários
      </MenuButton>
      {user.role === RoleEnum.Admin && (
        <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/admin/associations")}>
          <FontAwesomeIcon icon={faBuilding} />
          &nbsp; Associações
        </MenuButton>
      )}
      <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/events")}>
        <FontAwesomeIcon icon={faCalendar} />
        &nbsp; Eventos
      </MenuButton>
      <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/finances/vouchers")}>
        <FontAwesomeIcon icon={faTicket} />
        &nbsp; Vouchers de desconto
      </MenuButton>
      <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/admin/registrations")}>
        <FontAwesomeIcon icon={faListCheck} />
        &nbsp; Inscrições
      </MenuButton>
      <MenuButton size="lg" appearance="subtle" block onClick={() => props.onGo("/registration-key")}>
        <FontAwesomeIcon icon={faKey} />
        &nbsp; Chaves de inscrições
      </MenuButton>
      <MenuButton size="lg" appearance="subtle" block onClick={props.onSignout}>
        <FontAwesomeIcon icon={faRightFromBracket} />
        &nbsp; Sair
      </MenuButton>
      <img src="/logo.png" alt="Logo" style={{ width: 150, margin: "32px auto", display: "block" }} />
    </>
  );
}
