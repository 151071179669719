import * as yup from "yup";
import { DiscountTypeEnum } from "../../enums";
import { DateUtils } from "../../utils";

export interface VoucherFormInterface {
  id?: string;
  name: string;
  discountType: DiscountTypeEnum;
  maxRedemptions: number;
  numberOfRedemptions: number;
  isActive: boolean;
  activeAt: Date;
  expiresAt?: Date;
  eventId?: string;
  discountAmount: number;
}

export abstract class VoucherFormInterfaceHandler {
  public static default(): VoucherFormInterface {
    return {
      name: "",
      discountType: DiscountTypeEnum.Unit,
      numberOfRedemptions: 0,
      maxRedemptions: 0,
      isActive: true,
      discountAmount: 0,
      eventId: "",
      activeAt: new Date(),
    };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      name: yup
        .string()
        .required("Informe o nome")
        .min(3, "Informe pelo menos 3 caracteres"),
      eventId: yup.string(),
      maxRedemptions: yup
        .number()
        .positive("O valor deve ser positivo")
        .min(0)
        .required("Informe o número máximo de conversões"),
      numberOfRedemptions: yup
        .number()
        .positive("O valor deve ser positivo")
        .min(0)
        .required("Informe o número de conversões"),
      activeAt: yup
        .date()
        .required("Informe a data de início")
        .nonNullable("Informe a data de início"),
      discountAmount: yup.number().positive("O valor deve ser positivo").min(0),
      expiresAt: yup
        .date()
        .nullable()
        .test(
          "expiresAt",
          "A data de término deve ser maior ou igual a data de início.",
          (value, context) => {
            const { activeAt } = context.parent;

            return !value || !activeAt
              ? true
              : !DateUtils.compareOnlyDates(activeAt, value);
          }
        ),
    });
  }
}
