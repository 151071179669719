import { create } from "zustand";
import {
  CitySearchInputInterface,
  CitySearchOutputInterface,
} from "../interfaces";
import { CityService } from "../services";

interface useCityStoreProps {
  search: (
    stateId: string,
    input: CitySearchInputInterface
  ) => Promise<CitySearchOutputInterface>;
}

export const useCityStore = create<useCityStoreProps>(() => ({
  search: (stateId: string, input: CitySearchInputInterface) =>
    CityService.search(stateId, input),
}));
