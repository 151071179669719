export enum AspectRatioEnum {
  Aspect1x1,
  Aspect16x9,
}

export abstract class AspectRatioEnumHandler {
  public static cssMeaure(
    aspect: AspectRatioEnum,
    width: number
  ): { width: number; height: number } {
    switch (aspect) {
      case AspectRatioEnum.Aspect16x9:
        const height: number = (width / 16) * 9;
        return { width, height };
      default:
        return { width, height: width };
    }
  }
  public static imageCropAspect(aspect: AspectRatioEnum): number {
    switch (aspect) {
      case AspectRatioEnum.Aspect16x9:
        return 16 / 9;
      default:
        return 1;
    }
  }
}
