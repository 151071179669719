import axios from "axios";
import {
  CreateRegistrationKeyInputInterface,
  RegistrationKeySearchInputInterface,
  RegistrationKeySearchOutputInterface,
} from "../interfaces/registration-keys";

export abstract class RegistrationKeyService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(
    input: RegistrationKeySearchInputInterface
  ): Promise<RegistrationKeySearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/registration-keys?${params}`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar buscar chave de inscrição."));
    });
  }

  static create(input: CreateRegistrationKeyInputInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/registration-keys`;
      axios
        .post(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar criar nova chave de inscrição."));
    });
  }

  static bulkDeletion(ids: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      resolve();
      /*const url = `${this.baseURL}/api/registration-keys/bulk-deletion`;
      axios
        .post(url, { registrationKeyIds: ids })
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar remover chave de inscrição."));*/
    });
  }
}
