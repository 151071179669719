import * as yup from "yup";
export interface SigninInputInterface {
  email: string;
  password: string;
}

export abstract class SigninInputInterfaceHandler {
  public static default(): SigninInputInterface {
    return { email: "", password: "" };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      email: yup
        .string()
        .email("Informe um email válido")
        .required("Informe o email"),
      password: yup
        .string()
        .required("Informe a senha")
        .min(6, "A senha deve conter pelo menos 6 caracteres"),
    });
  }
}
