import { IconButton, Stack } from "rsuite";
import { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Col24 } from "./cols.component";

interface ActionSectionAction {
  icon: IconDefinition;
  onClick?: () => void;
}

interface ActionSectionProps {
  prefix?: string | ReactNode;
  children: string | ReactNode;
  actions: ActionSectionAction[];
  pointer?: boolean;
  spacing?: number;
}

export function ActionSection(props: ActionSectionProps) {
  const { prefix: sulfix, children, actions, pointer, spacing } = props;

  function handleClick(event: any, action: ActionSectionAction): void {
    event && event.stopPropagation();
    action.onClick && action.onClick();
  }

  return (
    <Col24>
      <Stack
        alignItems="flex-start"
        spacing={spacing || 10}
        style={{ cursor: pointer ? "pointer" : "default" }}
      >
        <div style={{ verticalAlign: "middle" }}>
          {sulfix && <Stack.Item alignSelf="flex-start">{sulfix}</Stack.Item>}
        </div>
        <Stack.Item grow={1} style={{ textAlign: "start" }}>
          {children}
        </Stack.Item>
        {actions.length !== 0 && (
          <Stack.Item alignSelf="flex-start">
            {actions.map((action, index) => (
              <IconButton
                key={index}
                size="sm"
                icon={<FontAwesomeIcon icon={action.icon} size="sm" />}
                onClick={(event) => handleClick(event, action)}
              />
            ))}
          </Stack.Item>
        )}
      </Stack>
    </Col24>
  );
}
