import axios from "axios";
import {
  FormUserInputInterface,
  UserSearchInputInterface,
  UserSearchInterfaceHandler,
  UserSearchOutputInterface,
} from "../interfaces/users";

export abstract class UserService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(
    input: UserSearchInputInterface
  ): Promise<UserSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/users?${params}`;
      axios
        .get(url)
        .then((res) =>
          resolve({
            ...res.data,
            items: res.data.items.map((item: any) =>
              UserSearchInterfaceHandler.fromApi(item)
            ),
          })
        )
        .catch(() => reject("Erro ao tentar buscar usuários."));
    });
  }

  static create(input: FormUserInputInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/users`;
      axios
        .post(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar criar usuário."));
    });
  }

  static edit(input: FormUserInputInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/users/${input.id}`;
      axios
        .put(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar editar usuário."));
    });
  }

  static delete(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/users/${id}`;
      axios
        .delete(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar remover usuário."));
    });
  }
}
