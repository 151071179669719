import { Button, FlexboxGrid, Loader } from "rsuite";
import * as yup from "yup";
import { GeneralUtils, InputText, PanelBase, useAccountStore, useToasterStore } from "../../../shared";
import { useState } from "react";
import { useStore } from "zustand";
import { useNavigate } from "react-router-dom";

export function ResetPassword() {
  const navigate = useNavigate();
  const toasterStore = useStore(useToasterStore);
  const accountStore = useStore(useAccountStore);
  const [processing, setProcessing] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const schema: yup.AnyObject = yup.object({
    email: yup.string().email("Informe um email válido").required("Informe o email"),
  });

  function handleSubmit(): void {
    schema
      .validate({ email }, { abortEarly: false })
      .then(() => {
        setErrors({});
        setProcessing(true);
        accountStore
          .resetPassword(email)
          .then(() => {
            toasterStore.success("Solicitação de alteração de senha feita com sucesso.");
            navigate("/confirm-reset-password");
          })
          .catch(toasterStore.error)
          .finally(() => setProcessing(false));
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)));
  }

  if (processing) {
    return <Loader size="md" center content="Aguarde..." />;
  }

  return (
    <PanelBase title="Solicitar alteração de senha" hideTitleDivider>
      <FlexboxGrid style={{ gap: 32, justifyContent: "center" }}>
        <img src="/logo.png" alt="Logo" style={{ width: 150 }} />
        <InputText label="Email" value={email} error={errors.email} onChange={setEmail} />
      </FlexboxGrid>
      <FlexboxGrid style={{ marginTop: 32 }}>
        <Button block appearance="primary" onClick={handleSubmit}>
          Enviar solicitação
        </Button>
        <Button block appearance="link" onClick={() => navigate("/")}>
          Entrar
        </Button>
      </FlexboxGrid>
    </PanelBase>
  );
}
