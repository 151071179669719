import { List, Stack } from "rsuite";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { ActionListItemInterface } from "../interfaces/action-list-item.interface";
import { IconButtonCustom } from "./action-list-item.styles";

interface ActionListItemProps {
  item: ActionListItemInterface;
  pointer?: boolean;
  onClick?: (item: ActionListItemInterface) => void;
  onEdit?: (item: ActionListItemInterface) => void;
  onRemove?: (item: ActionListItemInterface) => void;
}

export function ActionListItem(props: ActionListItemProps) {
  const { item, pointer, onClick, onEdit, onRemove } = props;
  return (
    <List.Item style={{ cursor: pointer ? "pointer" : "default" }}>
      <Stack alignItems="flex-start" spacing={10}>
        {item.prefix && (
          <div onClick={() => onClick && onClick(item)}>{item.prefix}</div>
        )}
        <Stack.Item grow={1}>
          <div style={{ width: "100%" }}>
            <Stack alignItems="flex-start">
              <Stack.Item grow={1}>
                <div onClick={() => onClick && onClick(item)}>
                  <h6>{item.primary}</h6>
                  {item.secondary && <span>{item.secondary}</span>}
                </div>
              </Stack.Item>
              <Stack.Item alignSelf="flex-start">
                {onEdit && (
                  <IconButtonCustom
                    icon={item.icon || <EditIcon />}
                    onClick={() => onEdit && onEdit(item)}
                  />
                )}
                {onRemove && (
                  <IconButtonCustom
                    icon={item.icon || <TrashIcon />}
                    onClick={() => onRemove && onRemove(item)}
                  />
                )}
              </Stack.Item>
            </Stack>
          </div>
        </Stack.Item>
      </Stack>
    </List.Item>
  );
}
