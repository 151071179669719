import * as yup from "yup";

export interface AssociationFormInterface {
  id?: string;
  name: string;
  code: string;
}

export abstract class AssociationFormInterfaceHandler {
  public static default(): AssociationFormInterface {
    return {
      name: "",
      code: "",
    };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      name: yup.string().required("Informe o nome").min(3, "Informe pelo menos 3 caracteres"),
      code: yup.string().required("Informe o código").min(3, "Informe pelo menos 3 caracteres"),
    });
  }
}
