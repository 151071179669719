import { create } from "zustand";
import {
  StateSearchInputInterface,
  StateSearchOutputInterface,
} from "../interfaces";
import { StateService } from "../services";

interface useStateStoreProps {
  search: (
    input: StateSearchInputInterface
  ) => Promise<StateSearchOutputInterface>;
}

export const useStateStore = create<useStateStoreProps>(() => ({
  search: (input: StateSearchInputInterface) => StateService.search(input),
}));
