import * as yup from "yup";

export interface EventFormInterface {
  id?: string;
  name: string;
  description: string;
  startDate?: Date;
  endDate?: Date;
  bannerImageDataUrl: string;
  stateId?: string;
  cityId?: string;
  associationId?: string;
  isDeleted: boolean;
}

export abstract class EventFormInterfaceHandler {
  public static default(): EventFormInterface {
    return {
      name: "",
      description: "",
      bannerImageDataUrl: "",
      isDeleted: false,
    };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      name: yup
        .string()
        .required("Informe o nome")
        .min(3, "Informe pelo menos 3 caracteres"),
      description: yup
        .string()
        .required("Informe a descrição")
        .min(3, "Informe pelo menos 3 caracteres"),
      startDate: yup
        .date()
        .required("Informe a data de início")
        .nonNullable('"Informe a data de início"'),
      endDate: yup
        .date()
        .required("Informe a data de término")
        .nonNullable('"Informe a data de término"'),
      bannerImageDataUrl: yup
        .string()
        .required("Informe a imagem")
        .min(1, '"Informe a imagem"'),
      stateId: yup.string().required("Informe um estado"),
      cityId: yup.string().required("Informe uma cidade"),
      associationId: yup.string().required("Informe a associação"),
    });
  }
}
