import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import { Events } from "../../events";
import { Vouchers } from "../../finances/vouchers";
import {
  Associations,
  ResetPassword,
  ConfirmResetPassword,
  Signin,
  User,
  Registrations,
  Dashboard,
} from "../../admin";
import { RegistrationKeys } from "../../registration-key";
import { useStore } from "zustand";
import { useAccountStore } from "../stores";
import { AuthorizedMasterpage } from "./masterpages/authorized-masterpage";
import { UnauthorizedMasterpage } from "./masterpages/unauthorized-masterpage";

export function Routes() {
  const accountStore = useStore(useAccountStore);

  if (!accountStore.authenticated) {
    return (
      <UnauthorizedMasterpage>
        <ReactRoutes>
          <Route path="/*" element={<Navigate to="/signin" replace />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/confirm-reset-password"
            element={<ConfirmResetPassword />}
          />
        </ReactRoutes>
      </UnauthorizedMasterpage>
    );
  }

  return (
    <AuthorizedMasterpage>
      <ReactRoutes>
        <Route path="/*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/admin/users" element={<User />} />
        <Route path="/admin/associations" element={<Associations />} />
        <Route path="/admin/registrations" element={<Registrations />} />
        <Route path="/events" element={<Events />} />
        <Route path="/registration-key" element={<RegistrationKeys />} />
        <Route path="/finances/vouchers" element={<Vouchers />} />
      </ReactRoutes>
    </AuthorizedMasterpage>
  );
}
