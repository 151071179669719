export enum PaymentStatusEnum {
  Pending = "pending",
  Completed = "completed",
  Error = "error",
}

export abstract class PaymentStatusEnumHandler {
  public static label(val: PaymentStatusEnum): string {
    switch (val) {
      case PaymentStatusEnum.Pending:
        return "Pendente";
      case PaymentStatusEnum.Completed:
        return "Pago";
      default:
        return "Erro";
    }
  }

  public static selectOptions(): any[] {
    return Object.values(PaymentStatusEnum).map((val: any) => ({
      label: PaymentStatusEnumHandler.label(val),
      value: val,
    }));
  }
}
