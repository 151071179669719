export enum PaymentMethodEnum {
  Pix = "pix",
  CreditCard = "creditCard",
}

export abstract class PaymentMethodEnumHandler {
  public static label(val: PaymentMethodEnum): string {
    switch (val) {
      case PaymentMethodEnum.Pix:
        return "PIX";
      default:
        return "Cartão de Crédito";
    }
  }

  public static selectOptions(): any[] {
    return Object.values(PaymentMethodEnum).map((val: any) => ({
      label: PaymentMethodEnumHandler.label(val),
      value: val,
    }));
  }
}
