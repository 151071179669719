import { useState } from "react";
import { Form, Slider, Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Cropper, { Area } from "react-easy-crop";

import {
  AspectRatioEnum,
  AspectRatioEnumHandler,
} from "./enums/aspect-ratio.enum";
import { Container } from "./input-image.styles";
import { FormModal } from "../../modals";
import { useStore } from "zustand";
import { useToasterStore } from "../../../stores";
import { Col24, Spacer } from "../../forms";
import { GeneralUtils } from "../../../utils";

interface ImageSize {
  width: number;
  height: number;
}

interface InputImageProps {
  label?: string | null | undefined;
  image?: string | null | undefined;
  error?: string | null | undefined;
  cropSize: ImageSize;
  maxCropSize?: ImageSize;
  aspectRatio?: AspectRatioEnum;
  onCrop: (blob?: string) => void;
  onError?: (error: string) => void;
}

export function InputImage(props: InputImageProps) {
  const toasterStore = useStore(useToasterStore);
  const { image, cropSize, maxCropSize, onCrop, onError } = props;
  const aspectRatio: AspectRatioEnum =
    props.aspectRatio || AspectRatioEnum.Aspect1x1;
  const [imageCropSrc, setImageCropSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, ...(cropSize as any) });
  const [croppedArea, setCroppedArea] = useState<Area | null>(null);
  const [zoom, setZoom] = useState<number>(1);

  function getImageSize(blob: string): Promise<ImageSize> {
    const img = window.document.createElement("img");

    return new Promise((resolve) => {
      img.onload = () => {
        resolve({
          width: img.naturalWidth || img.width,
          height: img.naturalHeight || img.height,
        });
      };

      img.src = blob;
    });
  }

  async function getFileContent(data: File | Blob): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result?.toString() || "");
      reader.readAsDataURL(data);
    });
  }

  async function createImageObject(blob: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img: HTMLImageElement = window.document.createElement("img");
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.setAttribute("crossOrigin", "anonymous");
      img.src = blob;
    });
  }

  async function handleChangeFile(fileList: FileType[]): Promise<void> {
    if (fileList.length === 0) {
      return;
    }

    const file: FileType = fileList[0];
    const source = Boolean(file.url)
      ? file.url || ""
      : await getFileContent(file.blobFile!);

    const imageSize: ImageSize = await getImageSize(source);
    if (
      imageSize.width < cropSize!.width &&
      imageSize.height < cropSize!.height
    ) {
      const errorMsg = `A imagem deve ter o tamanho de pelo menos ${cropSize?.width}x${cropSize?.height}`;
      if (Boolean(onError)) {
        return onError!(errorMsg);
      }

      toasterStore.error(errorMsg);
      return;
    }

    setImageCropSrc(source);
  }

  async function handleCrop(): Promise<void> {
    const img = await createImageObject(imageCropSrc!);

    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return;
    }

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    // set canvas size to match the bounding box
    const croppedCanvas = document.createElement("canvas");
    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return;
    }

    // Set the size of the cropped canvas
    const clampWidth = GeneralUtils.clamp(
      croppedArea!.width,
      cropSize.width,
      maxCropSize?.width || cropSize.width
    );
    const clampHeight = GeneralUtils.clamp(
      croppedArea!.height,
      cropSize.height,
      maxCropSize?.height || cropSize.height
    );

    croppedCanvas.width = clampWidth;
    croppedCanvas.height = clampHeight;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      croppedArea!.x,
      croppedArea!.y,
      croppedArea!.width,
      croppedArea!.height,
      0,
      0,
      clampWidth,
      clampHeight
    );

    onCrop(croppedCanvas.toDataURL("image/jpeg", 1));
    setImageCropSrc(null);
  }

  return (
    <Form.Group style={{ width: "100%" }}>
      <Form.ControlLabel>{props.label}</Form.ControlLabel>

      <Container>
        <Uploader
          listType="picture"
          autoUpload={false}
          action=""
          onChange={handleChangeFile}
          onRemove={() => onCrop()}
          fileList={!!image ? [{ url: image }] : undefined}
          draggable
          disabled={!!image}
        >
          <button>
            <FontAwesomeIcon icon={faCamera} />
          </button>
        </Uploader>
        <FormModal
          title="Recortar imagem"
          open={Boolean(imageCropSrc)}
          onClose={() => setImageCropSrc(null)}
          onSave={handleCrop}
          saveText="Concluir"
        >
          <Col24
            style={{
              display: "flex",
              width: "100%",
              padding: "40%",
            }}
          >
            <Cropper
              image={imageCropSrc || ""}
              crop={crop}
              restrictPosition={false}
              minZoom={1}
              maxZoom={3}
              zoom={zoom}
              aspect={AspectRatioEnumHandler.imageCropAspect(aspectRatio)}
              onCropChange={setCrop}
              onCropComplete={(_, area) => setCroppedArea(area)}
              onZoomChange={setZoom}
            />
          </Col24>
          <Col24 style={{ marginTop: 20 }}>
            <Slider
              aria-label="Zoom"
              min={1}
              max={3}
              step={0.1}
              value={zoom}
              onChange={setZoom}
            />
          </Col24>
          <Spacer />
        </FormModal>
      </Container>
      <Form.ErrorMessage show={Boolean(props.error)}>
        {props.error}
      </Form.ErrorMessage>
    </Form.Group>
  );
}
