/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  ActionList,
  AlertModal,
  RoleEnumHandler,
  UserSearchInterface,
  UserSearchOutputInterface,
  useToasterStore,
  useUserStore,
  PaginationInterface,
  PaginationInterfaceHandler,
  InputSearch,
  Pagination,
  PanelBase,
  FormUserInputInterface,
  UserRegistryInterfaceHandler,
  UserRegistryInterface,
  RoleEnum,
  useAccountStore,
  PaginationInputInterface,
  ExportUtils,
} from "../../../shared";
import { useStore } from "zustand";
import { UserSearchDetails } from "./user-search-details.component";
import { UserForm } from "./user-form.compoment";

export function User() {
  const toasterStore = useStore(useToasterStore);
  const accountStore = useStore(useAccountStore);
  const userStore = useStore(useUserStore);
  const [users, setUsers] = useState<UserSearchInterface[]>([]);
  const [user, setUser] = useState<UserSearchInterface | null>(null);
  const [userForm, setUserForm] = useState<UserRegistryInterface | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );
  const isAdmin = accountStore.role && accountStore.role === RoleEnum.Admin;

  useEffect(() => {
    loadUsers(pagination);
  }, []);

  function loadUsers(input: PaginationInterface): void {
    setProcessing(true);

    userStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: UserSearchOutputInterface) => {
        setPagination({ ...input, count: output.count });
        setUsers(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination,
      filter: search,
    };

    setPagination(newPagination);
    loadUsers(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadUsers(value);
  }

  function handleSave(user: FormUserInputInterface): void {
    const input: FormUserInputInterface = {
      id: user.id,
      name: user.name,
      email: user.email,
      associationId: user.associationId!,
      role: user.role!,
    };

    setProcessing(true);

    const action = user.id ? userStore.edit(input) : userStore.create(input);

    action
      .then(() => {
        loadUsers(pagination);
        setUserForm(null);
        toasterStore.success(
          user.id
            ? "Usuário atualizado com sucesso!"
            : "Usuário criado com sucesso!"
        );
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleDelete(user: UserSearchInterface): void {
    setProcessing(true);

    userStore
      .delete(user.id)
      .then(() => {
        loadUsers(pagination);
        toasterStore.success("Usuário removido com sucesso!");
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleDownload(): void {
    const input: PaginationInputInterface =
      PaginationInterfaceHandler.toPaginationInput({
        ...pagination,
        page: 0,
        pageSize: pagination.count,
      });

    userStore
      .search(input)
      .then((output: UserSearchOutputInterface) =>
        ExportUtils.toCSV(output.items, { fileName: "usuarios.csv" })
      )
      .catch(() => toasterStore.error("Erro ao tentar baixar usuários."));
  }

  return (
    <PanelBase
      title={`Usuários (${pagination.count})`}
      onAdd={() => setUserForm(UserRegistryInterfaceHandler.default())}
      addButtonTitle="Criar"
      onDownload={handleDownload}
    >
      <InputSearch
        placeholder="Filtrar"
        debounce={500}
        onChange={(i) => handleSearch(i)}
      />
      <ActionList
        hover={true}
        pointer={true}
        loading={processing}
        confirmRemoveMessage={(item) =>
          `Deseja realmente remover "${item.value.name}"?`
        }
        onEdit={isAdmin ? (user) => setUserForm(user.value) : undefined}
        onRemove={(user) => handleDelete(user.value)}
        onClick={(item) => setUser(item.value)}
        items={users.map((user) => ({
          primary: user.name,
          secondary: RoleEnumHandler.label(user.role),
          value: user,
        }))}
      />
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
      <AlertModal
        title={`Detalhes do(a) ${user?.name}`}
        open={Boolean(user)}
        onClose={() => setUser(null)}
      >
        <UserSearchDetails user={user!} />
      </AlertModal>

      <UserForm
        user={userForm}
        onSave={(user) => handleSave(user)}
        onClose={() => setUserForm(null)}
      />
    </PanelBase>
  );
}
