import { useNavigate } from "react-router";
import { Drawer } from "rsuite";
import { MenuOptions } from "../menu-options";

interface MobileTemplateDrawerProps {
  open: boolean;
  onClose: () => void;
  onSignout: () => void;
}

export function MobileTemplateDrawer(props: MobileTemplateDrawerProps) {
  const navigate = useNavigate();

  function handleGoTo(path: string): void {
    navigate(path);
    props.onClose();
  }

  return (
    <Drawer placement="left" size={250} open={props.open} onClose={props.onClose}>
      <MenuOptions onGo={handleGoTo} onSignout={props.onSignout} />
    </Drawer>
  );
}
