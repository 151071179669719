import { create } from "zustand";
import {
  AssociationInterface,
  AssociationSearchInputInterface,
  AssociationSearchOutputInterface,
  AssociationUpsertInputInterface,
} from "../interfaces";
import { AssociationService } from "../services";

interface useAssociationStoreProps {
  create: (input: AssociationUpsertInputInterface) => Promise<AssociationInterface>;
  update: (id: string, input: AssociationUpsertInputInterface) => Promise<AssociationInterface>;
  search: (input: AssociationSearchInputInterface) => Promise<AssociationSearchOutputInterface>;
}

export const useAssociationStore = create<useAssociationStoreProps>(() => ({
  create: (input: AssociationUpsertInputInterface) => AssociationService.create(input),
  update: (id: string, input: AssociationUpsertInputInterface) => AssociationService.update(id, input),
  search: (input: AssociationSearchInputInterface) => {
    return AssociationService.search(input);
  },
}));
