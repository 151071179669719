import * as yup from "yup";
export interface ConfirmResetPasswordInputInterface {
  token: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export abstract class ConfirmResetPasswordInputInterfaceHandler {
  public static default(): ConfirmResetPasswordInputInterface {
    return { token: "", email: "", password: "", passwordConfirmation: "" };
  }

  public static schema(): yup.AnyObject {
    return yup.object({
      email: yup
        .string()
        .email("Informe um email válido")
        .required("Informe o email"),
      token: yup
        .string()
        .required("Informe o código")
        .min(5, "O código deve conter pelo menos 5 caracteres"),
      password: yup
        .string()
        .required("Informe a senha")
        .min(6, "A senha deve conter pelo menos 6 caracteres"),
      passwordConfirmation: yup
        .string()
        .required("Informe a senha")
        .min(6, "A senha deve conter pelo menos 6 caracteres")
        .test(
          "passwordConfirmation",
          "A confirmação deve ser igual a senha digitada.",
          (value, context) => value === context.parent.password
        ),
    });
  }
}
