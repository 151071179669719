import { InputDatePicker } from "./input-date-picker.component";

interface InputDatePickerProps {
  label?: string;
  placeholder?: string;
  value?: Date;
  error?: string | null | undefined;
  options?: any;
  onChange?: (date: Date | null) => void;
}

export function InputDateTimePicker(props: InputDatePickerProps) {
  return <InputDatePicker format="dd/MM/yyyy hh:mm" {...props} />;
}
