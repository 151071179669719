import { Divider, Heading, Progress, Text, useMediaQuery } from "rsuite";
import { GeneralUtils, LotsPerformaceInterface } from "../../../shared";
import { Fragment } from "react/jsx-runtime";
import * as S from "./styles";

type StatsCardProps = {
  lotData: LotsPerformaceInterface[];
};

export const ProgressLine = ({ lotData }: StatsCardProps) => {
  const isMobile = useMediaQuery("(max-width: 687px)");
  function percentValue(
    value: number,
    total: number,
    decimals: number = 0
  ): number {
    return total > 0
      ? parseFloat(((value / total) * 100).toFixed(decimals))
      : 0;
  }

  return (
    <S.ContainerProgress>
      {lotData.map((lot, index) => (
        <Fragment key={index}>
          <div
            style={{
              flex: "1",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Heading style={{ marginTop: "10px", color: "#a8a8a8" }} level={5}>
              {lot.lotName}
            </Heading>
            <Text style={{ marginBottom: "10px", color: "#a8a8a8" }}>
              {lot.lotDescription}
            </Text>
            <Text style={{ marginBottom: "5px" }}>
              Valor Coletado: {GeneralUtils.getAmountLabel(lot.amountCollected)}
            </Text>
            <Progress.Line
              percent={percentValue(lot.amountCollected, lot.amountExpected)}
              strokeColor="#4BC0C0"
              status={
                percentValue(lot.amountCollected, lot.amountExpected) === 100
                  ? "success"
                  : "active"
              }
            />

            <Text style={{ margin: "10px 0 5px" }}>
              Descontos Totais:{" "}
              {GeneralUtils.getAmountLabel(lot.totalDiscounts)}
            </Text>
            <Progress.Line
              percent={percentValue(lot.totalDiscounts, lot.amountExpected)}
              strokeColor="#FFCE56"
              status={
                percentValue(lot.totalDiscounts, lot.amountExpected) === 100
                  ? "success"
                  : "active"
              }
            />

            <Heading style={{ marginTop: "10px", color: "#a8a8a8" }} level={5}>
              Valor Esperado:{" "}
              <span style={{ color: "#4BC0C0" }}>
                {GeneralUtils.getAmountLabel(lot.amountExpected)}
              </span>
            </Heading>
          </div>
          {index !== lotData.length - 1 && (
            <Divider
              vertical={!isMobile[0]}
              style={{ height: !isMobile[0] ? "auto" : "" }}
            />
          )}
        </Fragment>
      ))}
    </S.ContainerProgress>
  );
};
