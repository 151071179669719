export enum RoleEnum {
  Standard = "standard",
  Staff = "staff",
  Secretary = "secretary",
  Admin = "admin",
}

export abstract class RoleEnumHandler {
  public static label(val: RoleEnum): string {
    switch (val) {
      case RoleEnum.Standard:
        return "Padrão";
      case RoleEnum.Staff:
        return "Equipe";
      case RoleEnum.Secretary:
        return "Secretária(o)";
      case RoleEnum.Admin:
        return "Administrador";
    }
  }
}
