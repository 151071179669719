import {
  Property,
  RoleEnumHandler,
  UserSearchInterface,
} from "../../../shared";
import { FlexboxGrid, Placeholder } from "rsuite";

interface UserSearchDetailsProps {
  user?: UserSearchInterface;
}

export function UserSearchDetails(props: UserSearchDetailsProps) {
  const { user } = props;

  if (!user) {
    return <Placeholder.Paragraph rows={3} active />;
  }

  return (
    <>
      <FlexboxGrid justify="space-between" align="top">
        <Property label="Nome" title={user.name} colSize={12} />
        <Property
          label="Cargo"
          title={RoleEnumHandler.label(user.role)}
          colSize={12}
        />
        <Property label="Associação" title={user.fieldName} colSize={12} />
      </FlexboxGrid>
    </>
  );
}
