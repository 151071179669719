/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "zustand";
import {
  ActionList,
  AlertModal,
  ExportUtils,
  InputSearch,
  Pagination,
  PaginationInputInterface,
  PaginationInterface,
  PaginationInterfaceHandler,
  PanelBase,
  RegistrationKeyFormInterface,
  RegistrationKeyInterface,
  RegistrationKeySearchOutputInterface,
  useRegistrationKeyStore,
  useToasterStore,
} from "../../shared";
import { useEffect, useState } from "react";
import { RegistrationKeySearchDetails } from "./registration-key-search-details.component";
import { RegistrationKeyForm } from "./registration-key-form-component";
import { RegistrationKeyModalSuccessComponent } from "./registration-key-modal-success-component";

export function RegistrationKeys() {
  const toasterStore = useStore(useToasterStore);
  const registrationKeyStore = useStore(useRegistrationKeyStore);

  const [registrationsKeys, setRegistrationsKeys] = useState<
    RegistrationKeyInterface[]
  >([]);
  const [registrationKey, setRegistrationKey] =
    useState<RegistrationKeyInterface | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );

  useEffect(() => {
    loadRegistrationKeys(pagination);
  }, []);

  function loadRegistrationKeys(input: PaginationInterface): void {
    setProcessing(true);

    registrationKeyStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: any) => {
        setPagination({ ...input, count: output.count });
        setRegistrationsKeys(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination!,
      filter: search,
    };
    setPagination(newPagination);
    loadRegistrationKeys(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadRegistrationKeys(value);
  }

  function handleSave(registrationKey: RegistrationKeyFormInterface): void {
    setProcessing(true);

    registrationKeyStore
      .create(registrationKey)
      .then(() => {
        loadRegistrationKeys(pagination);
        setShowModal(false);
        setShowModalSuccess(true);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleDownload(): void {
    const input: PaginationInputInterface =
      PaginationInterfaceHandler.toPaginationInput({
        ...pagination,
        page: 0,
        pageSize: pagination.count,
      });

    registrationKeyStore
      .search(input)
      .then((output: RegistrationKeySearchOutputInterface) =>
        ExportUtils.toCSV(output.items, {
          fileName: "chaves-de-inscrição.csv",
        })
      )
      .catch(() =>
        toasterStore.error("Erro ao tentar baixar as chaves de inscrição.")
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleDelete(registrationKey: RegistrationKeyInterface): void {
    setProcessing(true);

    registrationKeyStore
      .bulkDeletion([registrationKey.key])
      .then(() => {
        loadRegistrationKeys(pagination);
        setRegistrationKey(null);
        toasterStore.success("Chave de incrição removida com sucesso!");
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  return (
    <PanelBase
      title={`Chaves de incrição (${pagination.count})`}
      onAdd={() => setShowModal(true)}
      addButtonTitle="Criar"
      onDownload={handleDownload}
    >
      <InputSearch
        placeholder="Filtrar"
        debounce={500}
        onChange={(i) => handleSearch(i)}
      />
      <ActionList
        pointer={true}
        hover={true}
        loading={processing}
        onClick={(item) => setRegistrationKey(item.value)}
        confirmRemoveMessage={(item) =>
          `Deseja realmente remover a chave de inscrição ${item.value.key}?`
        }
        onRemove={undefined /*(item) => handleDelete(item.value)*/}
        items={registrationsKeys.map((registrationKey) => ({
          primary: registrationKey.key,
          secondary: registrationKey.userName || "Não utilizado",
          value: registrationKey,
        }))}
      />
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
      <AlertModal
        title={"Detalhes da chave de incrição"}
        open={Boolean(registrationKey)}
        onClose={() => setRegistrationKey(null)}
      >
        <RegistrationKeySearchDetails registrationKey={registrationKey!} />
      </AlertModal>
      {showModal && (
        <RegistrationKeyForm
          onClose={() => setShowModal(false)}
          onSave={(registrationKey) => handleSave(registrationKey)}
        />
      )}
      {showModalSuccess && (
        <RegistrationKeyModalSuccessComponent
          open={showModalSuccess}
          onClose={() => setShowModalSuccess(false)}
          onDownload={() => setShowModalSuccess(false)} //TODO - FUNCTION DOWNLOAD CSV RETORNO SUCCESS
        />
      )}
    </PanelBase>
  );
}
