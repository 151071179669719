import { DateUtils } from "../../utils";
import { EventLotInterface } from "./event-lots";

export interface EventInterface {
  id: string;
  name: string;
  description: string;
  bannerImageUrl: string;
  startDate: Date;
  endDate: Date;
  isDeleted: boolean;
  cityId: string;
  cityName: string;
  stateId: string;
  stateName: string;
  stateCode: string;
  associationId: string;
  associationCode: string;
  associationName: string;
  lots: EventLotInterface[];
}

export abstract class EventInterfaceHandler {
  public static default(id?: string): EventInterface {
    return {
      id: id || "",
      name: "",
      description: "",
      bannerImageUrl: "",
      startDate: new Date(),
      endDate: new Date(),
      isDeleted: false,
      cityId: "",
      cityName: "",
      stateId: "",
      stateName: "",
      stateCode: "",
      associationId: "",
      associationCode: "",
      associationName: "",
      lots: [],
    };
  }

  public static fromApi(data: any): EventInterface {
    return {
      id: data.id,
      name: data.name,
      description: data.description,
      bannerImageUrl: data.bannerImageUrl,
      startDate: DateUtils.fromDateTime(data.startDate, data.startTime),
      endDate: DateUtils.fromDateTime(data.endDate, data.endTime),
      isDeleted: data.isDeleted,
      cityId: data.cityId,
      cityName: data.cityName,
      stateId: data.stateId,
      stateName: data.stateName,
      stateCode: data.stateCode,
      associationId: data.associationId,
      associationCode: data.associationCode,
      associationName: data.associationName,
      lots: data.lots,
    };
  }
}
