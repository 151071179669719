/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "zustand";
import {
  ActionList,
  AlertModal,
  ConfirmModal,
  ExportUtils,
  InputSearch,
  Pagination,
  PaginationInputInterface,
  PaginationInterface,
  PaginationInterfaceHandler,
  PanelBase,
  RegistrationInterface,
  RegistrationSearchOutputInterface,
  useRegistrationStore,
  useToasterStore,
} from "../../shared";
import { useEffect, useState } from "react";
import { RegistrationSearchDetails } from "./registration-search-details.component";

export function Registrations() {
  const toasterStore = useStore(useToasterStore);
  const registrationStore = useStore(useRegistrationStore);

  const [registrations, setRegistrations] = useState<RegistrationInterface[]>(
    []
  );
  const [registrationDetails, setRegistrationDetails] =
    useState<RegistrationInterface | null>(null);
  const [registrationCancel, setRegistrationCancel] =
    useState<RegistrationInterface | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );

  useEffect(() => {
    loadRegistrations(pagination);
  }, []);

  function loadRegistrations(input: PaginationInterface): void {
    setProcessing(true);

    registrationStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: RegistrationSearchOutputInterface) => {
        setPagination({ ...input, count: output.count });
        setRegistrations(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination!,
      filter: search,
    };
    setPagination(newPagination);
    loadRegistrations(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadRegistrations(value);
  }

  function handleCancel(): void {
    setProcessing(true);

    registrationStore
      .cancel(registrationCancel!.id)
      .then(() => {
        setRegistrationDetails(null);
        setRegistrationCancel(null);

        toasterStore.success("Inscrição cancelada com sucesso.");

        loadRegistrations(pagination);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleDownload(): void {
    const input: PaginationInputInterface =
      PaginationInterfaceHandler.toPaginationInput({
        ...pagination,
        page: 0,
        pageSize: pagination.count,
      });

    registrationStore
      .search(input)
      .then((output: RegistrationSearchOutputInterface) =>
        ExportUtils.toCSV(output.items, { fileName: "inscrições.csv" })
      )
      .catch(() => toasterStore.error("Erro ao tentar baixar as inscrições."));
  }

  return (
    <PanelBase
      title={`Inscrições (${pagination.count})`}
      onDownload={handleDownload}
    >
      <InputSearch
        placeholder="Filtrar"
        debounce={500}
        onChange={(i) => handleSearch(i)}
      />
      <ActionList
        hover
        pointer
        loading={processing}
        onClick={(item) => setRegistrationDetails(item.value)}
        items={registrations.map((registration) => ({
          primary: registration.name,
          value: registration,
          secondary: registration.lotDescription,
        }))}
      />
      <AlertModal
        title={"Detalhes da inscrição"}
        open={Boolean(registrationDetails)}
        onClose={() => setRegistrationDetails(null)}
      >
        <RegistrationSearchDetails
          registration={registrationDetails!}
          onCancel={setRegistrationCancel}
        />
      </AlertModal>
      <ConfirmModal
        title="Cancelar Registro"
        open={Boolean(registrationCancel)}
        onClose={() => setRegistrationCancel(null)}
        onConfirm={handleCancel}
      >
        <p>Deseja realmente remover este registro?</p>
      </ConfirmModal>
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
    </PanelBase>
  );
}
