import { useMemo } from "react";
import {
  Col24,
  DashboardInterface,
  GenderEnumHandler,
  PanelBase,
} from "../../../shared";
import { Divider, FlexboxGrid, Text } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressLine } from "./progressLine-component";
import { ChartComponent } from "./chart-component";
import { faLocationDot, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import zoomPlugin from "chartjs-plugin-zoom";
import { Pie, Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Title,
  ChartDataLabels,
  zoomPlugin
);

type EventPanelProps = {
  event: DashboardInterface["eventsStatistics"][number];
};

export const EventPanel: React.FC<EventPanelProps> = ({ event }) => {
  const maxLocationCount = Math.max(
    ...event.analyticsByLocation.map((field) => field.count)
  );

  const ageData = useMemo(
    () => ({
      labels: event.analyticsByAge.map((field) => field.ageRange),
      datasets: [
        {
          label: "Participantes por faixa etária",
          data: event.analyticsByAge.map((field) => field.count),
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#6dc562",
          ],
        },
      ],
    }),
    [event.analyticsByAge]
  );

  const genderData = useMemo(
    () => ({
      labels: event.analyticsByGender.map((field) =>
        GenderEnumHandler.labelString(field.gender)
      ),
      datasets: [
        {
          label: "Gênero dos participantes",
          data: event.analyticsByGender.map((field) => field.count),
          backgroundColor: ["#FF6384", "#36A2EB"],
        },
      ],
    }),
    [event.analyticsByGender]
  );

  const locationData = useMemo(
    () => ({
      labels: event.analyticsByLocation.map((field) => field.cityName),
      datasets: [
        {
          label: "Participantes por localização",
          data: event.analyticsByLocation.map((field) => field.count),
          borderColor: "#8abfe2",
          backgroundColor: "#36A2EB",
          borderWidth: 2,
        },
      ],
    }),
    [event.analyticsByLocation]
  );

  const optionsDoughnut = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Participantes por faixa etária",
      },
    },
    cutout: "50%",
  };

  const optionsPie = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Gênero dos participantes",
      },
    },
  };

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
        text: "Participantes por localização",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: (value: number) => value,
        color: "#5f5f5f",
        font: {
          size: 8,
        },
        clip: true,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: maxLocationCount + 100,
        ticks: {
          stepSize: 2,
        },
      },
    },
  };

  return (
    <PanelBase key={event.eventName} title={event.eventName} hideTitleDivider>
      <FlexboxGrid style={{ gap: "10px", alignItems: "center" }}>
        <Text style={{ color: "#7c7c7c" }}>
          <FontAwesomeIcon icon={faLocationDot} /> - {event.eventCityName}
        </Text>
        <Text style={{ color: "#7c7c7c" }}>
          <FontAwesomeIcon icon={faUsers} /> - {event.numberOfSubscriptions}{" "}
          inscritos
        </Text>
      </FlexboxGrid>
      <Divider />
      <FlexboxGrid style={{ gap: "18px", justifyContent: "space-around" }}>
        <Col24>
          <ProgressLine lotData={event.lotsPerformance} />
        </Col24>
        <Divider />
        <ChartComponent
          title="Faixa Etária"
          ChartType={Doughnut}
          data={ageData}
          options={optionsDoughnut}
          style={{ width: "100%", maxWidth: 300 }}
        />
        <ChartComponent
          title="Gênero"
          ChartType={Pie}
          data={genderData}
          options={optionsPie}
          style={{ width: "100%", maxWidth: 300 }}
        />
        <ChartComponent
          title="Localizações"
          ChartType={Bar}
          data={locationData}
          options={optionsBar}
          style={{
            maxWidth: 650,
            minHeight: 350,
            height: "100%",
            paddingTop: 20,
            width: "100%",
          }}
        />
      </FlexboxGrid>
    </PanelBase>
  );
};
