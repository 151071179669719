import { RoleEnum } from "../../enums";
import * as yup from "yup";
import { useAccountStore } from "../../stores";

export interface UserRegistryInterface {
  id?: string;
  name: string;
  email?: string;
  associationId?: string;
  role?: RoleEnum;
}

export abstract class UserRegistryInterfaceHandler {
  public static default(): UserRegistryInterface {
    return {
      name: "",
      email: "",
    };
  }

  public static schema(isEdit?: boolean): yup.AnyObject {
    const { role: defaultRole } = useAccountStore.getState();

    return yup.object({
      name: isEdit
        ? yup.string().notRequired()
        : yup
            .string()
            .required("Informe o nome")
            .min(3, "Informe pelo menos 3 caracteres"),
      email: isEdit
        ? yup.string().notRequired()
        : yup
            .string()
            .email("Informe um email válido.")
            .required("Informe o email"),
      role: yup
        .mixed()
        .oneOf(Object.values(RoleEnum))
        .test(
          "role-required",
          "Informe o cargo",
          (value) => defaultRole !== RoleEnum.Admin || Boolean(value)
        ),
      associationId: isEdit
        ? yup.string().notRequired()
        : yup
            .string()
            .test(
              "association-required",
              "Informe a associação",
              (value) => defaultRole !== RoleEnum.Admin || Boolean(value)
            ),
    });
  }
}
