/* eslint-disable react-hooks/exhaustive-deps */
import { useStore } from "zustand";
import { DashboardInterface, useToasterStore } from "../../shared";
import { useDashboardStore } from "../../shared/stores/dashboard.store";
import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Title,
} from "chart.js";
import { EventPanel } from "./components/eventPanel-component";
import { Placeholder } from "rsuite";

ChartJS.register(
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
  Title
);

export function Dashboard() {
  const toasterStore = useStore(useToasterStore);
  const dashboardStore = useStore(useDashboardStore);

  const [dashboardData, setDashboardData] = useState<DashboardInterface | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  useEffect(() => {
    loadDahsboard();
  }, []);

  function loadDahsboard(): void {
    setProcessing(true);
    dashboardStore
      .search()
      .then((output: DashboardInterface) => {
        setDashboardData(output);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  if (processing) return <Placeholder.Paragraph rows={6} active />;

  return (
    <>
      {dashboardData?.eventsStatistics.map((event) => (
        <EventPanel key={event.eventName} event={event} />
      ))}
    </>
  );
}
