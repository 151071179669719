import axios from "axios";
import {
  RegistrationInterface,
  RegistrationInterfaceHandler,
  RegistrationSearchInputInterface,
  RegistrationSearchOutputInterface,
} from "../interfaces";

export abstract class RegistrationService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(
    input: RegistrationSearchInputInterface
  ): Promise<RegistrationSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/registrations?${params}`;
      axios
        .get(url)
        .then((res) =>
          resolve({
            ...res.data,
            items: res.data.items.map((item: RegistrationInterface) =>
              RegistrationInterfaceHandler.fromApi(item)
            ),
          })
        )
        .catch(() => reject("Erro ao tentar obter registros."));
    });
  }

  static cancel(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/registration/${id}/cancellation`;
      axios
        .put(url, { reason: "Cancelado pelo usuário" })
        .then(() => resolve())
        .catch(() => reject("Erro ao tentar cancelar registro."));
    });
  }
}
