import { useEffect, useState } from "react";
import {
  AssociationFormInterface,
  AssociationFormInterfaceHandler,
  Col24,
  Col6,
  FormModal,
  GeneralUtils,
  InputText,
} from "../../shared";
import { FlexboxGrid, Placeholder } from "rsuite";

interface AssociationFormProps {
  association?: AssociationFormInterface | null;
  onClose?: () => void;
  onSave?: (association: AssociationFormInterface) => void;
}
export function AssociationForm(props: AssociationFormProps) {
  const [association, setAssociation] = useState<AssociationFormInterface>(
    AssociationFormInterfaceHandler.default()
  );
  const [errors, setErrors] = useState<any>({});
  const schema = AssociationFormInterfaceHandler.schema();

  useEffect(() => {
    setErrors({});
    props.association && setAssociation(props.association);
  }, [props.association]);

  function handleChangeValue(key: string, value: any): void {
    setAssociation({ ...association!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(association, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(association!);
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)));
  }
  return (
    <FormModal
      open={Boolean(props.association)}
      title={`${association && association.id ? "Editar" : "Criar"} associação`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!association ? (
        <Placeholder.Paragraph rows={2} active />
      ) : (
        <FlexboxGrid justify="space-between" align="top" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <InputText
              label="Nome"
              value={association.name}
              onChange={(value) => handleChangeValue("name", value)}
              error={errors.name}
            />
          </Col24>
          <Col6>
            <InputText
              label="Codigo"
              value={association.code}
              onChange={(value) => handleChangeValue("code", value)}
              error={errors.code}
            />
          </Col6>
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
