import axios from "axios";
import { DashboardInterface } from "../interfaces";

export abstract class DashboardService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(): Promise<DashboardInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/dashboard`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar obter dashboard."));
    });
  }
}
