import { RoleEnum } from "../../enums";

export interface UserSearchInterface {
  id: string;
  name: string;
  fieldId: string;
  fieldName: string;
  role: RoleEnum;
  email?: string;
}

export abstract class UserSearchInterfaceHandler {
  public static fromApi(data: any): UserSearchInterface {
    const result: UserSearchInterface = {
      id: data.id,
      name: data.name,
      fieldId: data.fieldId,
      fieldName: data.fieldName,
      role: data.roleName as RoleEnum,
    };

    if (data.email) {
      result.email = data.email;
    }

    return result;
  }
}
