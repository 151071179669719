/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useStore } from "zustand";
import {
  DateUtils,
  EventFormInterface,
  EventFormInterfaceHandler,
  EventInterface,
  EventLotCreateInputInterface,
  EventLotFormInterface,
  EventLotUpdateInputInterface,
  EventSearchOutputInterface,
  EventUpsertInputInterface,
  InputSearch,
  Pagination,
  PaginationInterface,
  PaginationInterfaceHandler,
  PanelBase,
  useEventStore,
  useToasterStore,
} from "../../shared";
import { EventDetails } from "./event-details.component";
import { EventForm } from "./event-form.component";
import { PanelGroup, Placeholder } from "rsuite";

export function Events() {
  const toasterStore = useStore(useToasterStore);
  const eventStore = useStore(useEventStore);
  const [events, setEvents] = useState<EventInterface[]>([]);
  const [eventForm, setEventForm] = useState<EventFormInterface | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );

  useEffect(() => {
    loadEvents(pagination);
  }, []);

  function loadEvents(input: PaginationInterface): void {
    setProcessing(true);

    eventStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: EventSearchOutputInterface) => {
        setPagination({ ...input, count: output.count });
        setEvents(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination,
      filter: search,
    };

    setPagination(newPagination);
    loadEvents(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadEvents(value);
  }

  function handleEdit(event: EventInterface): void {
    setEventForm({
      id: event.id,
      name: event.name,
      bannerImageDataUrl: event.bannerImageUrl,
      description: event.description,
      associationId: event.associationId,
      stateId: event.stateId,
      cityId: event.cityId,
      startDate: event.startDate,
      endDate: event.endDate,
      isDeleted: event.isDeleted,
    });
  }

  function handleSave(event: EventFormInterface): void {
    const start = DateUtils.toDateTime(event.startDate!);
    const end = DateUtils.toDateTime(event.endDate!);
    const input: EventUpsertInputInterface = {
      name: event.name,
      description: event.description,
      startDate: start.date,
      startTime: start.time,
      endDate: end.date,
      endTime: end.time,
      cityId: event.cityId!,
      associationId: event.associationId!,
      isDeleted: event.isDeleted,
    };

    if (
      event.bannerImageDataUrl &&
      event.bannerImageDataUrl.indexOf("http") !== 0
    ) {
      input.bannerImageDataUrl = event.bannerImageDataUrl;
    }

    setProcessing(true);

    if (event.id) {
      eventStore
        .update(event.id, input)
        .then(() => {
          toasterStore.success("Event salvo com sucesso!");
          setEventForm(null);
          loadEvents(pagination);
        })
        .catch(toasterStore.error)
        .finally(() => setProcessing(false));
      return;
    }

    eventStore
      .create(input)
      .then(() => {
        toasterStore.success("Event salvo com sucesso!");
        setEventForm(null);
        loadEvents(pagination);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleCreateLot(lot: EventLotFormInterface): void {
    const input: EventLotCreateInputInterface = {
      eventId: lot.eventId!,
      description: lot.description,
      address: lot.address,
      price: lot.price,
      quantityAvailable: lot.quantityAvailable,
      cityId: lot.cityId!,
      associationId: lot.associationId!,
    };

    setProcessing(true);
    eventStore
      .createLot(lot.eventId!, input)
      .then(() => {
        toasterStore.success("Lote salvo com sucesso!");
        loadEvents(pagination);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleUpdateLot(lot: EventLotFormInterface): void {
    const input: EventLotUpdateInputInterface = {
      description: lot.description,
      address: lot.address,
      price: lot.price,
      quantityAvailable: lot.quantityAvailable,
      cityId: lot.cityId!,
      associationId: lot.associationId!,
    };

    setProcessing(true);
    eventStore
      .updateLot(lot.id!, input)
      .then(() => {
        toasterStore.success("Lote salvo com sucesso!");
        loadEvents(pagination);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  if (processing) {
    <Placeholder.Paragraph rows={5} active />;
  }

  return (
    <>
      <PanelBase
        title={`Eventos (${events.length})`}
        onAdd={() => setEventForm(EventFormInterfaceHandler.default())}
        addButtonTitle="Criar"
      >
        <InputSearch
          placeholder="Filtrar"
          debounce={500}
          onChange={(i) => handleSearch(i)}
        />
        <PanelGroup accordion>
          {events.map((event, index) => (
            <EventDetails
              key={index}
              event={event}
              onEdit={handleEdit}
              onCreateLot={handleCreateLot}
              onEditLot={handleUpdateLot}
            />
          ))}
        </PanelGroup>
      </PanelBase>
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
      <EventForm
        event={eventForm}
        onClose={() => setEventForm(null)}
        onSave={handleSave}
      />
    </>
  );
}
