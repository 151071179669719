import { FlexboxGrid, Placeholder } from "rsuite";
import {
  GeneralUtils,
  PaymentStatusEnumHandler,
  Property,
  RegistrationKeyInterface,
} from "../../shared";

interface RegistrationKeySearchDetailsProps {
  registrationKey?: RegistrationKeyInterface;
}

export function RegistrationKeySearchDetails(
  props: RegistrationKeySearchDetailsProps
) {
  const { registrationKey } = props;

  if (!registrationKey) {
    return <Placeholder.Paragraph rows={3} active />;
  }

  return (
    <>
      <FlexboxGrid justify="space-between" align="top">
        <Property label="Chave" title={registrationKey.key} colSize={12} />
        {registrationKey.userId && (
          <>
            {registrationKey.userName && (
              <Property
                label="Nome"
                title={registrationKey.userName}
                colSize={24}
              />
            )}
            {registrationKey.userEmail && (
              <Property
                label="E-mail"
                title={registrationKey.userEmail}
                colSize={24}
              />
            )}
            {registrationKey.userPhone && (
              <Property
                label="Telefone"
                title={GeneralUtils.maskPhonenumber(registrationKey.userPhone)}
                colSize={24}
              />
            )}
          </>
        )}
        <Property
          label="Status"
          title={
            !registrationKey.userId
              ? "Não utilizada"
              : PaymentStatusEnumHandler.label(registrationKey.paymentStatus)
          }
          colSize={24}
        />
        <Property
          label="Chave de Inscrição"
          title={registrationKey.key}
          colSize={24}
        />
        <Property
          label="Evento"
          title={registrationKey.eventName}
          colSize={24}
        />
        <Property label="Prefixo" title={registrationKey.prefix} colSize={12} />
        <Property label="Sulfixo" title={registrationKey.suffix} colSize={12} />
      </FlexboxGrid>
    </>
  );
}
