import type { ChartData } from "chart.js";

type ChartProps = {
  title: string;
  ChartType: React.ElementType;
  data: ChartData<"bar" | "line" | "pie" | "doughnut">;
  options?: any;
  style?: React.CSSProperties;
};

export const ChartComponent: React.FC<ChartProps> = ({ title, ChartType, data, options, style }) => (
  <div style={style}>
    <ChartType data={data} options={options} />
  </div>
);
