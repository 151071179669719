import { DatePicker, Form } from "rsuite";

interface InputDatePickerProps {
  label?: string;
  placeholder?: string;
  value?: Date;
  format?: string;
  error?: string | null | undefined;
  options?: any;
  onChange?: (date: Date | null) => void;
}

export function InputDatePicker(props: InputDatePickerProps) {
  return (
    <Form.Group style={{ width: "100%" }}>
      {props.label && (
        <div>
          <Form.ControlLabel>{props.label}</Form.ControlLabel>
        </div>
      )}
      <DatePicker
        value={props.value || null}
        defaultValue={props.value || null}
        placeholder={props.placeholder}
        oneTap
        format={props.format || "dd/MM/yyyy"}
        style={{ width: "100%" }}
        onChange={(event) => props.onChange && props.onChange(event)}
      />
      <Form.ErrorMessage show={Boolean(props.error)}>
        {props.error}
      </Form.ErrorMessage>
    </Form.Group>
  );
}
