import { Col, Grid } from "rsuite";
import styled from "styled-components";

export const Container = styled.div`
  background-image: url("./bg-login.png");
  background-size: cover;
  width: 100%;
`;

export const GridCustom = styled(Grid)`
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100vh;
  padding-top: 15vh;
  background-color: inherit;
`;

export const ColCustom = styled(Col)`
  padding: 0px;
  border: 0px;
`;
