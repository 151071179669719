/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FlexboxGrid, Placeholder, Row, Toggle } from "rsuite";
import {
  Col24,
  GeneralUtils,
  InputSelectAssociation,
  InputText,
  EventFormInterface,
  EventFormInterfaceHandler,
  InputDateTimePicker,
  InputSelectState,
  InputSelectCity,
  FormModal,
  InputImage,
  AspectRatioEnum,
  Col12,
} from "../../shared";

interface EventFormProps {
  event?: EventFormInterface | null;
  onClose?: () => void;
  onSave?: (event: EventFormInterface) => void;
}

export function EventForm(props: EventFormProps) {
  const [event, setEvent] = useState<EventFormInterface>(
    EventFormInterfaceHandler.default()
  );
  const [errors, setErrors] = useState<any>({});
  const schema = EventFormInterfaceHandler.schema();

  useEffect(() => {
    setErrors({});
    props.event && setEvent(props.event);
  }, [props.event]);

  function handleChangeState(stateId: string): void {
    setEvent({ ...event!, stateId, cityId: undefined });
  }

  function handleChangeValue(key: string, value: any): void {
    setEvent({ ...event!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(event, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(event!);
      })
      .catch((error: any) =>
        setErrors(GeneralUtils.takeValidationErrorObject(error))
      );
  }

  return (
    <FormModal
      open={Boolean(props.event)}
      title={`${event && event.id ? "Editar" : "Criar"} evento`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!event ? (
        <Placeholder.Paragraph rows={3} active />
      ) : (
        <FlexboxGrid align="top" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <Row>
              <InputImage
                label="Imagem"
                image={event.bannerImageDataUrl}
                cropSize={{ width: 960, height: 540 }}
                maxCropSize={{ width: 1280, height: 720 }}
                aspectRatio={AspectRatioEnum.Aspect16x9}
                error={errors.bannerImageDataUrl}
                onCrop={(img) => handleChangeValue("bannerImageDataUrl", img)}
              />
            </Row>
          </Col24>
          <Col24>
            <InputText
              label="Nome"
              value={event.name}
              error={errors.name}
              onChange={(val) => handleChangeValue("name", val)}
            />
          </Col24>
          <Col24>
            <InputText
              options={{ as: "textarea", rows: 4 }}
              label="Descrição"
              value={event.description}
              error={errors.description}
              onChange={(val) => handleChangeValue("description", val)}
            />
          </Col24>
          <Col12>
            <InputDateTimePicker
              label="Início"
              placeholder="Início"
              value={event.startDate}
              error={errors.startDate}
              onChange={(val) => handleChangeValue("startDate", val)}
            />
          </Col12>
          <Col12>
            <InputDateTimePicker
              label="Término"
              placeholder="Término"
              value={event.endDate}
              error={errors.endDate}
              onChange={(val) => handleChangeValue("endDate", val)}
            />
          </Col12>
          <Col24>
            <InputSelectState
              value={event.stateId}
              error={errors.stateId}
              onChange={(val) => handleChangeState(val)}
            />
          </Col24>
          {event.stateId && (
            <Col24>
              <InputSelectCity
                stateId={event.stateId}
                value={event.cityId}
                error={errors.cityId}
                onChange={(val) => handleChangeValue("cityId", val)}
              />
            </Col24>
          )}
          <Col24>
            <InputSelectAssociation
              label="Associação"
              value={event.associationId}
              error={errors.associationId}
              onChange={(val) => handleChangeValue("associationId", val)}
            />
          </Col24>
          <Col24>
            <Toggle
              size="lg"
              checkedChildren="Está ativo"
              unCheckedChildren="Está inativo"
              checked={!event.isDeleted}
              onChange={(val) => handleChangeValue("isDeleted", !val)}
            ></Toggle>
          </Col24>
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
