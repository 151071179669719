import { ColCustom, Container, GridCustom } from "./unauthorized-masterpage.styles";

export function UnauthorizedMasterpage(props: any) {
  return (
    <Container>
      <GridCustom>
        <ColCustom
          xsOffset={0}
          smOffset={0}
          mdOffset={0}
          lgOffset={6}
          xlOffset={6}
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          xxl={12}
        >
          {props.children}
        </ColCustom>
      </GridCustom>
    </Container>
  );
}
