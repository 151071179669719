/* eslint-disable react-hooks/exhaustive-deps */
import { Button, FlexboxGrid } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHouse } from "@fortawesome/free-solid-svg-icons";
import { CustomHeader, HomeButtom } from "./appbar.styles";

interface AppbarProps {
  onHome?: () => void;
  onMenu?: () => void;
}

const btStyle = {
  height: 64,
  borderRadius: 0,
  width: 64,
};

export function Appbar(props: AppbarProps) {
  return (
    <>
      <CustomHeader style={{ height: 64 }}>
        <FlexboxGrid>
          {props.onMenu && (
            <Button style={btStyle} appearance="subtle" onClick={props.onMenu}>
              <FontAwesomeIcon icon={faBars} style={{ fontSize: 20 }} />
            </Button>
          )}

          <HomeButtom
            appearance="subtle"
            onClick={props.onHome}
            startIcon={
              <FontAwesomeIcon icon={faHouse} style={{ fontSize: 20 }} />
            }
          >
            Painel
          </HomeButtom>
        </FlexboxGrid>
      </CustomHeader>
    </>
  );
}
