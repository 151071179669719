export enum GenderEnum {
  Female = "female",
  Male = "male",
  NotInformed = "not_informed",
}

export abstract class GenderEnumHandler {
  public static label(val: GenderEnum): string {
    switch (val) {
      case GenderEnum.Female:
        return "Feminino";
      case GenderEnum.Male:
        return "Masculino";
      case GenderEnum.NotInformed:
        return "Não informado";
    }
  }

  public static labelString(val: string): string {
    switch (val) {
      case "female":
        return "Feminino";
      case "male":
        return "Masculino";
      default:
        return "Não informado";
    }
  }
}
