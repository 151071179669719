/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import {
  ActionSection,
  EventLotFormInterface,
  EventLotFormInterfaceHandler,
  EventLotInterface,
} from "../../shared";
import { LotDetails } from "./lot-details.component";
import { LotForm } from "./lot-form.component";
import { Panel } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

interface LotsProps {
  eventId: string;
  lots: EventLotInterface[];
  onCreate: (lot: EventLotFormInterface) => void;
  onEdit: (lot: EventLotFormInterface) => void;
}

export function Lots(props: LotsProps) {
  const { eventId, onCreate, onEdit } = props;
  const [lots, setLots] = useState<EventLotInterface[]>(props.lots);
  const [lotForm, setLotForm] = useState<EventLotFormInterface | null>(null);

  useEffect(() => {
    setLots(props.lots);
  }, [props.lots]);

  function handleEdit(lot: EventLotInterface): void {
    setLotForm({
      id: lot.id,
      description: lot.description,
      stateId: lot.stateId,
      cityId: lot.cityId,
      associationId: lot.associationId,
      address: lot.address,
      price: lot.price,
      quantityAvailable: lot.quantityAvailable,
      eventId: lot.eventId,
    });
  }

  function handleSave(lot: EventLotFormInterface): void {
    if (lot.id) {
      onEdit && onEdit(lot);
      setLotForm(null);
      return;
    }

    onCreate && onCreate(lot);
    setLotForm(null);
  }

  return (
    <>
      <Panel
        header={
          <ActionSection
            actions={[
              {
                icon: faPlus,
                onClick: () =>
                  setLotForm({
                    ...EventLotFormInterfaceHandler.default(),
                    eventId,
                  }),
              },
            ]}
          >
            Lotes ({lots.length})
          </ActionSection>
        }
        expanded
        bordered
      >
        {lots.map((lot, index) => (
          <Panel
            key={index}
            header={
              <ActionSection
                actions={[{ icon: faPencil, onClick: () => handleEdit(lot) }]}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                &nbsp;{lot.description}
              </ActionSection>
            }
          >
            <LotDetails lot={lot} />
          </Panel>
        ))}
      </Panel>
      <LotForm
        lot={lotForm}
        onClose={() => setLotForm(null)}
        onSave={handleSave}
      />
    </>
  );
}
