import { create } from "zustand";
import {
  RegistrationSearchInputInterface,
  RegistrationSearchOutputInterface,
} from "../interfaces";
import { RegistrationService } from "../services";

interface useRegistrationStoreProps {
  search: (
    input: RegistrationSearchInputInterface
  ) => Promise<RegistrationSearchOutputInterface>;
  cancel: (id: string) => Promise<void>;
}

export const useRegistrationStore = create<useRegistrationStoreProps>(() => ({
  search: (input: RegistrationSearchInputInterface) => {
    return RegistrationService.search(input);
  },
  cancel: (id: string) => RegistrationService.cancel(id),
}));
