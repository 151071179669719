/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { useStore } from "zustand";
import {
  ActionList,
  AlertModal,
  VoucherFormInterface,
  VoucherFormInterfaceHandler,
  VoucherInterface,
  VoucherSearchOutputInterface,
  VoucherUpsertInputInterface,
  InputSearch,
  Pagination,
  PanelBase,
  useVoucherStore,
  useToasterStore,
  PaginationInterfaceHandler,
  PaginationInterface,
} from "../../../shared";
import { VoucherDetails } from "./voucher-details.component";
import { VoucherForm } from "./voucher-form.component";

export function Vouchers() {
  const toasterStore = useStore(useToasterStore);
  const voucherStore = useStore(useVoucherStore);
  const [vouchers, setVouchers] = useState<VoucherInterface[]>([]);
  const [voucherDetails, setVoucherDetails] = useState<VoucherInterface | null>(
    null
  );
  const [voucherForm, setVoucherForm] = useState<VoucherFormInterface | null>(
    null
  );
  const [processing, setProcessing] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationInterface>(
    PaginationInterfaceHandler.default()
  );

  useEffect(() => {
    loadVouchers(pagination);
  }, []);

  function loadVouchers(input: PaginationInterface): void {
    setProcessing(true);

    voucherStore
      .search(PaginationInterfaceHandler.toPaginationInput(input))
      .then((output: VoucherSearchOutputInterface) => {
        setPagination({ ...input, count: output.count });
        setVouchers(output.items);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  function handleSearch(search: string): void {
    const newPagination = {
      ...pagination,
      filter: search,
    };

    setPagination(newPagination);
    loadVouchers(newPagination);
  }

  function handleChangePagination(value: PaginationInterface): void {
    setPagination(value);
    loadVouchers(value);
  }

  function handleEdit(voucher: VoucherInterface): void {
    setVoucherForm({
      id: voucher.id,
      name: voucher.name,
      numberOfRedemptions: voucher.numberOfRedemptions,
      maxRedemptions: voucher.maxRedemptions,
      discountAmount: voucher.discountAmount,
      discountType: voucher.discountType,
      isActive: voucher.isActive,
      activeAt: voucher.activeAt,
      expiresAt: voucher.expiresAt,
      eventId: voucher.eventId,
    });
  }

  function handleSave(voucher: VoucherFormInterface): void {
    const input: VoucherUpsertInputInterface = {
      name: voucher.name,
      numberOfRedemptions: voucher.numberOfRedemptions,
      maxRedemptions: voucher.maxRedemptions,
      activeAt: voucher.activeAt.toISOString(),
      discountAmount: voucher.discountAmount,
      discountType: voucher.discountType,
      isActive: voucher.isActive,
      eventId: voucher.eventId,
    };

    if (voucher.expiresAt) {
      input.expiresAt = voucher.expiresAt.toISOString();
    }

    setProcessing(true);

    if (voucher.id) {
      voucherStore
        .update(voucher.id, input)
        .then(() => {
          toasterStore.success("Voucher salvo com sucesso!");
          setVoucherForm(null);
          loadVouchers(pagination);
        })
        .catch(toasterStore.error)
        .finally(() => setProcessing(false));
      return;
    }

    voucherStore
      .create(input)
      .then(() => {
        toasterStore.success("Voucher salvo com sucesso!");
        setVoucherForm(null);
        loadVouchers(pagination);
      })
      .catch(toasterStore.error)
      .finally(() => setProcessing(false));
  }

  return (
    <PanelBase
      title={`Vouchers de descontos (${pagination.count})`}
      onAdd={() => setVoucherForm(VoucherFormInterfaceHandler.default())}
      addButtonTitle="Criar"
    >
      <InputSearch
        placeholder="Filtrar"
        debounce={500}
        onChange={(i) => handleSearch(i)}
      />
      <ActionList
        hover={true}
        pointer={true}
        loading={processing}
        onEdit={(target) => handleEdit(target.value)}
        onClick={(item) => setVoucherDetails(item.value)}
        items={vouchers.map((voucher) => ({
          primary: voucher.name,
          secondary: `Voucher utilizados: ${voucher.numberOfRedemptions}/${voucher.maxRedemptions}`,
          value: voucher,
        }))}
      />
      {pagination.count > pagination.pageSize && (
        <Pagination pagination={pagination} onChange={handleChangePagination} />
      )}
      <AlertModal
        title={`Detalhes do voucher`}
        open={Boolean(voucherDetails)}
        onClose={() => setVoucherDetails(null)}
      >
        <VoucherDetails voucher={voucherDetails!} />
      </AlertModal>
      <VoucherForm
        voucher={voucherForm}
        onClose={() => setVoucherForm(null)}
        onSave={handleSave}
      />
    </PanelBase>
  );
}
