import axios from "axios";
import {
  VoucherInterface,
  VoucherInterfaceHandler,
  VoucherSearchInputInterface,
  VoucherSearchOutputInterface,
  VoucherUpsertInputInterface,
} from "../interfaces";

export abstract class VoucherService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static create(input: VoucherUpsertInputInterface): Promise<VoucherInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/finances/voucher`;
      axios
        .post(url, input)
        .then((res) => resolve(VoucherInterfaceHandler.fromApi(res.data)))
        .catch(() => reject("Erro ao tentar criar novo voucher."));
    });
  }

  static update(
    id: string,
    input: VoucherUpsertInputInterface
  ): Promise<VoucherInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/finances/voucher/${id}`;
      axios
        .put(url, input)
        .then((res) => resolve(VoucherInterfaceHandler.fromApi(res.data)))
        .catch(() => reject("Erro ao tentar atualizar voucher."));
    });
  }

  static search(
    input: VoucherSearchInputInterface
  ): Promise<VoucherSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/finances/voucher?${params}`;
      axios
        .get(url)
        .then((res) =>
          resolve({
            ...res.data,
            items: res.data.items.map((item: VoucherInterface) =>
              VoucherInterfaceHandler.fromApi(item)
            ),
          })
        )
        .catch(() => reject("Erro ao tentar obter lista de vouchers."));
    });
  }
}
