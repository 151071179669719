/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FlexboxGrid, Placeholder } from "rsuite";
import {
  Col24,
  GeneralUtils,
  InputSelectAssociation,
  InputText,
  EventLotFormInterface,
  EventLotFormInterfaceHandler,
  Col12,
  InputSelectState,
  InputSelectCity,
  FormModal,
  InputNumber,
  InputCurrency,
} from "../../shared";

interface LotFormProps {
  lot?: EventLotFormInterface | null;
  onClose?: () => void;
  onSave?: (lot: EventLotFormInterface) => void;
}

export function LotForm(props: LotFormProps) {
  const [lot, setLot] = useState<EventLotFormInterface>(EventLotFormInterfaceHandler.default());
  const [errors, setErrors] = useState<any>({});
  const schema = EventLotFormInterfaceHandler.schema();

  useEffect(() => {
    setErrors({});
    props.lot && setLot(props.lot);
  }, [props.lot]);

  function handleChangeState(stateId: string): void {
    setLot({ ...lot!, stateId, cityId: undefined });
  }

  function handleChangeValue(key: string, value: any): void {
    setLot({ ...lot!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(lot, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(lot!);
      })
      .catch((error: any) => setErrors(GeneralUtils.takeValidationErrorObject(error)));
  }

  return (
    <FormModal
      open={Boolean(props.lot)}
      title={`${lot && lot.id ? "Editar" : "Criar"} loto`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!lot ? (
        <Placeholder.Paragraph rows={3} active />
      ) : (
        <FlexboxGrid justify="space-between" align="top" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <InputText
              label="Descrição"
              value={lot.description}
              error={errors.description}
              onChange={(val) => handleChangeValue("description", val)}
            />
          </Col24>
          <Col12>
            <InputCurrency
              label="Preço"
              cents={lot.price}
              error={errors.price}
              onChange={(val) => handleChangeValue("price", val)}
            />
          </Col12>
          <Col12>
            <InputNumber
              label="Quantidade disponível"
              value={lot.quantityAvailable}
              error={errors.quantityAvailable}
              options={{ min: 1 }}
              onChange={(val) => handleChangeValue("quantityAvailable", val)}
            />
          </Col12>
          <Col24>
            <InputText
              label="Endereço"
              value={lot.address}
              error={errors.address}
              onChange={(val) => handleChangeValue("address", val)}
            />
          </Col24>
          <Col24>
            <InputSelectState
              value={lot.stateId}
              error={errors.stateId}
              onChange={(val) => handleChangeState(val)}
            />
          </Col24>
          {lot.stateId && (
            <Col24>
              <InputSelectCity
                stateId={lot.stateId}
                value={lot.cityId}
                error={errors.cityId}
                onChange={(val) => handleChangeValue("cityId", val)}
              />
            </Col24>
          )}
          <Col24>
            <InputSelectAssociation
              label="Associação"
              value={lot.associationId}
              error={errors.associationId}
              onChange={(val) => handleChangeValue("associationId", val)}
            />
          </Col24>
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
