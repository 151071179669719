import { create } from "zustand";
import {
  EventUpsertInputInterface,
  EventInterface,
  EventSearchInputInterface,
  EventSearchOutputInterface,
  EventLotInterface,
  EventLotUpdateInputInterface,
  EventLotSearchInputInterface,
  EventLotSearchOutputInterface,
} from "../interfaces";
import { EventService } from "../services";
import { EventLotCreateInputInterface } from "../interfaces/events/event-lots/event-lot-create-input.interface";

interface useEventStoreProps {
  get: (id: string) => Promise<EventInterface>;
  create: (input: EventUpsertInputInterface) => Promise<EventInterface>;
  update: (
    id: string,
    input: EventUpsertInputInterface
  ) => Promise<EventInterface>;
  search: (
    input: EventSearchInputInterface
  ) => Promise<EventSearchOutputInterface>;
  createLot: (
    eventId: string,
    input: EventLotCreateInputInterface
  ) => Promise<EventLotInterface>;
  updateLot: (
    id: string,
    input: EventLotUpdateInputInterface
  ) => Promise<EventLotInterface>;
  searchLot: (
    eventId: string,
    input: EventLotSearchInputInterface
  ) => Promise<EventLotSearchOutputInterface>;
}

export const useEventStore = create<useEventStoreProps>(() => ({
  get: (id: string) => EventService.get(id),
  create: (input: EventUpsertInputInterface) => EventService.create(input),
  update: (id: string, input: EventUpsertInputInterface) =>
    EventService.update(id, input),
  search: (input: EventSearchInputInterface) => EventService.search(input),
  createLot: (eventId: string, input: EventLotCreateInputInterface) =>
    EventService.createLot(eventId, input),
  updateLot: (id: string, input: EventLotUpdateInputInterface) =>
    EventService.updateLot(id, input),
  searchLot: (eventId: string, input: EventLotSearchInputInterface) =>
    EventService.searchLot(eventId, input),
}));
