import axios from "axios";
import {
  EventInterface,
  EventInterfaceHandler,
  EventSearchInputInterface,
  EventSearchOutputInterface,
  EventLotSearchInputInterface,
  EventLotSearchOutputInterface,
  EventLotInterface,
  EventLotUpdateInputInterface,
} from "../interfaces";
import { EventUpsertInputInterface } from "../interfaces/events/event-upsert.interface";
import { EventLotCreateInputInterface } from "../interfaces/events/event-lots/event-lot-create-input.interface";

export abstract class EventService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  // =================================================================================
  // EVENTS
  // =================================================================================

  static get(id: string): Promise<EventInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/event/${id}`;
      axios
        .get(url)
        .then((res) => resolve(EventInterfaceHandler.fromApi(res.data)))
        .catch(() => reject("Erro ao tentar retornar evento."));
    });
  }

  static create(input: EventUpsertInputInterface): Promise<EventInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/events`;
      axios
        .post(url, input)
        .then((res) => resolve(EventInterfaceHandler.fromApi(res.data)))
        .catch(() => reject("Erro ao tentar criar nova evento."));
    });
  }

  static update(
    id: string,
    input: EventUpsertInputInterface
  ): Promise<EventInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/events/${id}`;
      axios
        .put(url, input)
        .then((res) => resolve(EventInterfaceHandler.fromApi(res.data)))
        .catch(() => reject("Erro ao tentar atualizar evento."));
    });
  }

  static search(
    input: EventSearchInputInterface
  ): Promise<EventSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/admin/events/list?${params}`;
      axios
        .get(url)
        .then((res) =>
          resolve({
            ...res.data,
            items: res.data.items.map((item: EventInterface) =>
              EventInterfaceHandler.fromApi(item)
            ),
          })
        )
        .catch(() => reject("Erro ao tentar obter lista de eventos."));
    });
  }

  // =================================================================================
  // LOTS
  // =================================================================================

  static createLot(
    eventId: string,
    input: EventLotCreateInputInterface
  ): Promise<EventLotInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/events/${eventId}/lot`;
      axios
        .post(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar criar nova lote."));
    });
  }

  static updateLot(
    id: string,
    input: EventLotUpdateInputInterface
  ): Promise<EventLotInterface> {
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/events/lot/${id}`;
      axios
        .put(url, input)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar atualizar lote."));
    });
  }

  static searchLot(
    eventId: string,
    input: EventLotSearchInputInterface
  ): Promise<EventLotSearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/events/${eventId}/lots?${params}`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar obter lista de lotes."));
    });
  }
}
