import { create } from "zustand";
import {
  CreateRegistrationKeyInputInterface,
  RegistrationKeySearchInputInterface,
  RegistrationKeySearchOutputInterface,
} from "../interfaces";
import { RegistrationKeyService } from "../services";

interface useRegistrationKeyStoreProps {
  search: (
    input: RegistrationKeySearchInputInterface
  ) => Promise<RegistrationKeySearchOutputInterface>;
  create: (input: CreateRegistrationKeyInputInterface) => Promise<void>;
  bulkDeletion: (ids: string[]) => Promise<void>;
}

export const useRegistrationKeyStore = create<useRegistrationKeyStoreProps>(
  () => ({
    search: (input: RegistrationKeySearchInputInterface) => {
      return RegistrationKeyService.search(input);
    },
    create: (input: CreateRegistrationKeyInputInterface) =>
      RegistrationKeyService.create(input),
    bulkDeletion: (ids: string[]) => RegistrationKeyService.bulkDeletion(ids),
  })
);
