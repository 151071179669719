import axios from "axios";
import {
  CitySearchInputInterface,
  CitySearchOutputInterface,
} from "../interfaces";

export abstract class CityService {
  private static baseURL: string =
    process.env.REACT_APP_API_URL || "http://localhost:3001";

  static search(
    stateId: string,
    input: CitySearchInputInterface
  ): Promise<CitySearchOutputInterface> {
    const params = new URLSearchParams(input as any).toString();
    return new Promise((resolve, reject) => {
      const url = `${this.baseURL}/api/cities/${stateId}/search?${params}`;
      axios
        .get(url)
        .then((res) => resolve(res.data))
        .catch(() => reject("Erro ao tentar obter lista de cidades."));
    });
  }
}
