export abstract class DateUtils {
  public static fromDateTime(date: string, time: string): Date {
    return new Date(Date.parse(`${date}T${time}Z`));
  }

  public static toDateTime(date: Date): { date: string; time: string } {
    const parts = date.toISOString().replace("Z", "").split("T");
    return {
      date: parts[0],
      time: parts[1],
    };
  }

  public static localTime(
    val?: string,
    showTime = false,
    fallback = ""
  ): string {
    if (!val) {
      return fallback;
    }

    const date = new Date(val);

    if (!showTime) {
      return date.toLocaleDateString();
    }

    const timeParts = date.toLocaleTimeString().split(":");
    return `${date.toLocaleDateString()} ${timeParts[0]}:${timeParts[1]}`;
  }

  public static compareDates(
    start: Date,
    end: Date,
    greater?: boolean
  ): boolean {
    const startTime = start.getTime();
    const endTime = end.getTime();

    if (greater) {
      return startTime > endTime;
    }

    return startTime >= endTime;
  }

  public static compareOnlyDates(
    start: Date,
    end: Date,
    greater?: boolean
  ): boolean {
    const day = 1000 * 3600 * 24;
    const startDays = Math.round(start.getTime() / day);
    const endDays = Math.round(end.getTime() / day);

    if (greater) {
      return startDays > endDays;
    }

    return startDays >= endDays;
  }

  public static formatDate(date: string): string {
    const [year, month, day] = date.split("T")[0].split("-").map(Number);
    return new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }).format(new Date(year, month - 1, day));
  }

  public static formatTime(time: string): string {
    const [hours, minutes] = time.split(":").map(Number);
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(new Date(0, 0, 0, hours, minutes));
  }

  public static calculateAge(birthDate: string): number {
    const birth = new Date(Date.parse(birthDate));
    const today = new Date();

    let age = today.getFullYear() - birth.getFullYear();
    const isBeforeBirthday =
      today.getMonth() < birth.getMonth() ||
      (today.getMonth() === birth.getMonth() &&
        today.getDate() < birth.getDate());

    if (isBeforeBirthday) {
      age--;
    }

    return age;
  }
}
