import { Pagination as PaginationComponent } from "rsuite";
import { PaginationContainer } from "./pagination.styles";
import { PaginationInterface } from "../../../interfaces";

interface PaginationProps {
  pagination: PaginationInterface;
  onChange?: (pagination: PaginationInterface) => void;
}

export function Pagination(props: PaginationProps) {
  const { pagination, onChange } = props;

  return (
    <PaginationContainer>
      <PaginationComponent
        prev
        last
        next
        first
        size="sm"
        ellipsis={true}
        maxButtons={10}
        total={pagination.count}
        limit={pagination.pageSize}
        activePage={pagination.page}
        onChangePage={(page) => onChange && onChange({ ...pagination, page })}
      />
    </PaginationContainer>
  );
}
