import { Button } from "rsuite";
import FileDownloadIcon from "@rsuite/icons/FileDownload";

interface DownloadButtonProps {
  title?: string;
  onClick?: () => void;
  options?: any;
}

export function DownloadButton(props: DownloadButtonProps) {
  const options = props.options || {};

  return (
    <Button
      appearance="default"
      startIcon={<FileDownloadIcon />}
      onClick={() => props.onClick && props.onClick()}
      {...options}
    >
      {props.title || "Download"}
    </Button>
  );
}
