import { useStore } from "zustand";
import { useNavigate } from "react-router";
import { MenuOptions } from "../../menu-options";
import { SidenavContainer } from "./web-template-sidebar.styles";
import { useMasterpageStore } from "../../../../../stores";

interface MenuWebProps {
  onSignout: () => void;
}

export function MenuWeb(props: MenuWebProps) {
  const navigate = useNavigate();
  const masterpageStore = useStore(useMasterpageStore);

  function handleGoTo(path: string): void {
    navigate(path);
    masterpageStore.toggleDrawer();
  }

  return (
    <SidenavContainer>
      <MenuOptions onGo={handleGoTo} onSignout={props.onSignout} />
    </SidenavContainer>
  );
}
