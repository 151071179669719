/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { FlexboxGrid, Placeholder, Toggle } from "rsuite";
import {
  GeneralUtils,
  VoucherFormInterface,
  VoucherFormInterfaceHandler,
  Col12,
  FormModal,
  InputSelect,
  DiscountTypeEnum,
  DiscountTypeEnumHandler,
  InputNumber,
  InputDatePicker,
  InputCurrency,
  Col24,
  Col6,
  InputText,
  InputSelectEvent,
} from "../../../shared";

interface VoucherFormProps {
  voucher?: VoucherFormInterface | null;
  onClose?: () => void;
  onSave?: (voucher: VoucherFormInterface) => void;
}

export function VoucherForm(props: VoucherFormProps) {
  const [voucher, setVoucher] = useState<VoucherFormInterface>(
    VoucherFormInterfaceHandler.default()
  );
  const [errors, setErrors] = useState<any>({});
  const schema = VoucherFormInterfaceHandler.schema();

  useEffect(() => {
    setErrors({});
    props.voucher && setVoucher(props.voucher);
  }, [props.voucher]);

  function handleChangeValue(key: string, value: any): void {
    setVoucher({ ...voucher!, [key]: value });
  }

  function handleSubmit() {
    schema
      .validate(voucher, { abortEarly: false })
      .then(() => {
        setErrors({});
        props.onSave && props.onSave(voucher!);
      })
      .catch((error: any) =>
        setErrors(GeneralUtils.takeValidationErrorObject(error))
      );
  }

  return (
    <FormModal
      open={Boolean(props.voucher)}
      title={`${
        voucher && voucher.id ? "Editar" : "Criar"
      } voucher de desconto.`}
      onClose={props.onClose}
      onSave={handleSubmit}
    >
      {!voucher ? (
        <Placeholder.Paragraph rows={3} active />
      ) : (
        <FlexboxGrid align="top" style={{ gap: 32, padding: 16 }}>
          <Col24>
            <InputText
              label="Nome do voucher"
              value={voucher.name}
              onChange={(val) => handleChangeValue("name", val)}
            />
          </Col24>
          <Col24>
            <InputSelectEvent
              label={"Evento"}
              value={voucher.eventId}
              onChange={(val) => handleChangeValue("eventId", val)}
            />
          </Col24>
          <Col24>
            <InputSelect
              label="Tipo de desconto"
              value={voucher.discountType}
              onChange={(val) => handleChangeValue("discountType", val)}
              options={DiscountTypeEnumHandler.selectOptions()}
            />
          </Col24>
          <Col24>
            {voucher.discountType === DiscountTypeEnum.Unit ? (
              <InputCurrency
                label={`Valor de desconto`}
                cents={voucher.discountAmount}
                error={errors.discountAmount}
                onChange={(val) => handleChangeValue("discountAmount", val)}
              />
            ) : (
              <InputNumber
                label={`Porcentagem de desconto`}
                value={voucher.discountAmount}
                error={errors.discountAmount}
                sulfix="%"
                onChange={(val) => handleChangeValue("discountAmount", val)}
              />
            )}
          </Col24>
          <Col6>
            <InputDatePicker
              format="dd/MM/yyyy"
              label="Inicia em"
              placeholder="Início"
              value={voucher.activeAt}
              error={errors.activeAt}
              onChange={(val) => handleChangeValue("activeAt", val)}
            />
          </Col6>
          <Col6>
            <InputDatePicker
              label="Término"
              format="dd/MM/yyyy"
              placeholder="Término"
              value={voucher.expiresAt}
              error={errors.expiresAt}
              onChange={(val) => handleChangeValue("expiresAt", val)}
            />
          </Col6>
          <Col12>
            <InputNumber
              label="Número máximo de conversões"
              value={voucher.maxRedemptions}
              error={errors.maxRedemptions}
              onChange={(val) => handleChangeValue("maxRedemptions", val)}
            />
          </Col12>
          <Col24>
            <Toggle
              size="lg"
              checkedChildren="Está ativo"
              unCheckedChildren="Está inativo"
              checked={voucher.isActive}
              onChange={(val) => handleChangeValue("isActive", val)}
            ></Toggle>
          </Col24>
        </FlexboxGrid>
      )}
    </FormModal>
  );
}
