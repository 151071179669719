import { CsvOptionsInterface } from "./csv-options.interface";

export abstract class ExportUtils {
  public static toCSV(jsonData: any[], options: CsvOptionsInterface): void {
    if (!Array.isArray(jsonData) || jsonData.length === 0) {
      console.warn("Os dados JSON fornecidos estão vazios ou não são válidos.");
      return;
    }

    const { fileName = "data.csv", delimiter = "," } = options;

    const headers = Object.keys(jsonData[0]);

    const rows = jsonData.map((obj) =>
      headers.map((header) => JSON.stringify(obj[header] ?? "")).join(delimiter)
    );

    const csvContent = [headers.join(delimiter), ...rows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
